export const getItemFullAvailability = (item) => {
  let availability = {
    online: false,
    clickAndCollect: false,
    clickAndCollectSellersCount: 0,
    color: '#ea0717',
    message: 'Non disponible',
    atLeastOneChannel: false,
  };
  if (!item) {
    return availability;
  }
  const onlineAvailability = getItemOnlineAvailability(item);
  const clickAndCollectAvailability = getItemClickAndCollectAvailability(item);

  availability.online = onlineAvailability?.isOrderable > 0;
  availability.clickAndCollect = clickAndCollectAvailability?.isOrderable > 0;
  availability.clickAndCollectSellersCount = clickAndCollectAvailability?.numberOfSellers;
  availability.atLeastOneChannel = availability.online || availability.clickAndCollect;

  availability.color = availability.atLeastOneChannel ? '#7aca22' : '#ea0717';

  if (availability.online) {
    availability.message = 'Disponible';
  } else {
    if (availability.clickAndCollect) {
      availability.message = clickAndCollectAvailability?.message;
    } else {
      availability.message = onlineAvailability?.message;
      availability.color = onlineAvailability?.color;
    }
  }
  return availability;
};

export const getItemOnlineAvailability = (item) => {
  let availability = {
    isOrderable: false,
    code: 102,
    color: '#ea0717',
    message: 'Non disponible',
    numberOfSellers: 0,
  };
  if (!item) {
    return availability;
  }
  const onlineSellingInfo = item?.sellingInfo?.online;

  availability.isOrderable = isItemOrderableOnline(item);
  availability.code = onlineSellingInfo?.availability?.code;
  availability.color = onlineSellingInfo?.availability?.color;
  availability.message = onlineSellingInfo?.availability?.message;
  availability.numberOfSellers = onlineSellingInfo?.numberOfSellers;
  availability.estimatedDeliveryDate = onlineSellingInfo?.estimatedDeliveryDate;

  return availability;
};

export const getItemClickAndCollectAvailability = (item) => {
  let availability = {
    code: 102,
    color: '#ea0717',
    message: 'Non disponible',
    numberOfSellers: 0,
  };
  if (!item) {
    return availability;
  }
  const clickAndCollectSellingInfo = item?.sellingInfo?.clickAndCollect;

  availability.isOrderable = clickAndCollectSellingInfo?.numberOfSellers > 0;
  availability.code = clickAndCollectSellingInfo?.availability?.code;
  availability.color = clickAndCollectSellingInfo?.availability?.color;
  availability.message = clickAndCollectSellingInfo?.availability?.message;
  availability.numberOfSellers = clickAndCollectSellingInfo?.numberOfSellers;

  return availability;
};

export const getDefaultSellingPrint = (album) => {
  if (!album || album === 'undefined') return {};

  if (album.selectedPrint) {
    return album.selectedPrint;
  }
  return album.prints
    ? album.prints.find((print) => print?.objectId === album?.defaultSellingPrintObjectId) || {}
    : {};
};

export const isPrintPublished = (print) => {
  if (!print) {
    return false;
  }
  // allow users to add the album if the publication date is missing
  if (!print.publicationDate) {
    return true;
  }
  return new Date(print.publicationDate) < new Date();
};

export const isItemOrderableOnline = (item) => {
  const onlineAvailability = item?.sellingInfo?.online;
  const code = onlineAvailability?.availability?.code;
  const numberOfSellers = onlineAvailability?.numberOfSellers;
  return (code === 100 || code === 104 || code === 103) && Number(numberOfSellers) > 0;
};

export const isItemInOnlineStock = (item) => {
  const code = item?.sellingInfo?.online?.availability?.code;
  return code === 100;
};

export const isItemOutOfOnlineStock = (item) => {
  const code = item?.sellingInfo?.online?.availability?.code;
  return code === 102;
};

export const isItemOrderableInClickAndCollect = (item) => {
  const clickAndCollectAvailability = item?.sellingInfo?.clickAndCollect;
  const code = clickAndCollectAvailability?.availability?.code;
  const numberOfSellers = clickAndCollectAvailability?.numberOfSellers;
  return code === 100 && Number(numberOfSellers) > 0;
};

export const isItemOrderable = (item) => {
  return isItemOrderableInClickAndCollect(item) || isItemOrderableOnline(item);
};

export const formatAlbum = (album) => {
  let newAlbum = Object.assign({}, album, {
    isPublished: !album.publicationDate || new Date(album.publicationDate) < new Date(),
  });

  // format dates
  newAlbum.publicationDateFormattedAll = null;
  newAlbum.publicationDateFormattedShort = null;
  if (album.publicationDate && new Date(album.publicationDate)) {
    const publicationDate = new Date(album.publicationDate);
    let day = publicationDate.getUTCDate();
    day = day < 10 ? '0' + day : day;

    var month = publicationDate.getUTCMonth() + 1;
    month = month < 10 ? '0' + month : month;

    newAlbum.publicationDateFormattedAll = [day, month, publicationDate.getUTCFullYear()].join('/');
    newAlbum.publicationDateFormattedShort = [day, month].join('/');
  }
  return newAlbum;
};

export const getFormattedTitle = (serie = {}, album = {}) =>
  [
    `${serie.title || ''}${album.tome ? ` tome ${album.tome}` : ''}`,
    !!album.title && album.title !== serie.title ? album.title : null,
  ]
    .filter(Boolean)
    .join(' - ');

export const extractAlbumAndPrints = (responseAlbums) => {
  const albums = (responseAlbums || []).slice();
  let prints = [];
  albums.forEach((album) => {
    if (album.selectedPrint) {
      album.prints = (album.prints || []).concat(album.selectedPrint || []);
      delete album.selectedPrint;
    }
    prints = prints.concat(album.prints || []);
    album.prints = (album.prints || []).map((print) => print.objectId);
  });
  return { albums, prints };
};
