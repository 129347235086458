import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { loadTops } from 'bubble-reducers/src/reducers/tops';

import { getSeoForTopList } from '@/services/seo-utils';

import AlbumCard from '@/components/AlbumCard/AlbumCard';
import BreadCrumbs from '@/components/Breadcrumbs/BreadCrumbs';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import SerieVerticalCard from '@/components/SerieVerticalCard/SerieVerticalCard';
import ShareZone from '@/components/ShareZone/ShareZone';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';

import '../Top/Top.scss';
import './TopList.css';

const TopList = () => {
  const dispatch = useDispatch();

  const tops = useSelector((state) => state.tops.tops);
  const loadedTops = useSelector((state) => state.tops.loadedTops);

  useEffect(() => {
    dispatch(loadTops({ withData: true }));
  }, []);

  return (
    <div className="bb-background-light-grey">
      <BubbleHelmet seo={getSeoForTopList()} />
      <div alt="placeholder" className="top-image-container">
        <div className="container pb-5">
          <div className="d-flex align-items-center px-sm-n3 mb-n3">
            <BreadCrumbs currentObject={{ custom: true, name: 'Les Tops Bubble' }} />
            <ShareZone />
          </div>
          <div className="px-sm-3">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="d-flex justify-content-center flex-column py-4">
                  <h1 className="text-center bb-xxl-text-size fw-bold agenda-title-line-height pt-4">
                    Découvrez les tops
                    <br />
                    de la communauté Bubble
                  </h1>
                </div>
              </div>
            </div>

            {Object.entries(loadedTops || {}).map(([name, topItems]) => {
              const top = tops[name];
              const isSerieTop = top.dataType === 'series';
              return (
                <React.Fragment key={`container_${top.nameFrench}`}>
                  <div className="d-flex flex-fill justify-content-between pt-5 align-items-center pb-2">
                    <h2 className="bb-large-text-size mb-0">{top.nameFrench}</h2>
                    <Link
                      className="bb-medium-large-text-size link-to-primary-color text-end pt-1 pt-md-0"
                      to={`/${top.permalink}/tops/${top.name}`}
                    >
                      Voir le top
                    </Link>
                  </div>
                  <p className="mb-n4" style={{ position: 'relative', top: '10px' }}>
                    {top.description || ''}
                  </p>
                  <TitledListContainer
                    noWrap
                    list={topItems.slice(0, 6).map((item, index) => {
                      return isSerieTop ? (
                        <SerieVerticalCard
                          key={`${top.name}_${item.serie.objectId}`}
                          className="col-5 col-md-2"
                          coverAddon={
                            <div className="bb-medium-large-text-size text-black score-panel score-panel-serie bg-warning">
                              {index + 1}
                            </div>
                          }
                          serie={Object.assign({}, item.serie, { images: item.images })}
                        />
                      ) : (
                        <AlbumCard
                          key={`${top.name}_${item.album.objectId}`}
                          className="col-7 col-md-2"
                          albumObjectId={item.album.objectId}
                          album={item.album}
                          serie={item.album.serie}
                          print={item.album.selectedPrint}
                          coverAddon={
                            <div className="bb-medium-large-text-size text-black score-panel bg-warning">
                              {index + 1}
                            </div>
                          }
                        />
                      );
                    })}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="py-6">
          <div className="container">
            <p className="font-italic my-0 text-secondary">
              Mieux que les cours de la bourse : retrouvez en temps réel les dernières tendances BD,
              comics & manga. Tous les tops se mettent à jour à partir des réactions des membres de
              la communauté. Des séries les plus attendues aux plus commentées, les derniers albums
              les plus vendus, les plus ajoutés dans les collections années par année…
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopList;
