import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Carousel from 'react-bootstrap/Carousel';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { loadMyReviewsForAlbum } from 'bubble-reducers/src/reducers/reviews';
import { loadUserVerifiedPurchases } from 'bubble-reducers/src/reducers/user';
import { isAlbumOwned, makeIsAlbumInCart } from 'bubble-reducers/src/selectors';

import { getFormattedTitle } from 'bubble-utils/src/album-utils';

import { useIsUserlogged } from '@/services/hooks/useUserUtils';

import AttachedPromotionCard from '@/components/AttachedPromotionCard/AttachedPromotionCard';
import Cover from '@/components/Cover/Cover';
import EditorChoiceBadge from '@/components/EditorChoiceBadge/EditorChoiceBadge';
import { Icon } from '@/components/Icon/Icon';
import AddAlbumReviewModal from '@/components/Modals/AddAlbumReviewModal';
import ImageBrowsingModal from '@/components/Modals/ImageBrowsingModal';
import RewardBadge from '@/components/RewardBadge/RewardBadge';
import SerieTerminatedBadge from '@/components/SerieTerminatedBadge/SerieTerminatedBadge';
import StarLine from '@/components/StarLine/StarLine';
import Star from '@/components/StarLine/components/Star';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import AlbumBuySection from './components/AlbumBuySection/AlbumBuySection';
import AlbumPrintsSection from './components/AlbumPrintsSection';
import CollectionActionsArray from './components/CollectionActionsArray';
import YoutubeEmbed from './components/YoutubeEmbed';

import { GENERAL } from 'bubble-constants';
import bubbleUtils from 'bubble-utils';

import './AlbumHeader.scss';

const AlbumHeader = (props) => {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  const album = props.album;
  const print = props.print;
  const serie = props.serie;

  const isAlbumInCart = makeIsAlbumInCart();

  const user = useSelector((state) => state.user.user);
  const myAlbumReviews = useSelector((state) => state.reviews.myAlbumReviewsMap[album?.objectId]);
  const isInCart = useSelector((state) =>
    isAlbumInCart(state, print?.objectId || album?.defaultSellingPrintObjectId),
  );
  const isOwned = useSelector((state) => isAlbumOwned(state, album?.objectId));

  const [hoveredIndex, setHoveredIndex] = useState(0);
  const [showAlbumReviewModal, setShowAlbumReviewModal] = useState(false);
  const [showImageBrowsingModal, setShowImageBrowsingModal] = useState(false);

  const isVerifiedPurchase = useSelector(
    (state) => state.user.verifiedPurchasesMap[print?.objectId],
  );

  useEffect(() => {
    if (user?.objectId && album?.objectId) {
      dispatch(
        loadMyReviewsForAlbum({ userObjectId: user.objectId, albumObjectId: album.objectId }),
      );
      dispatch(loadUserVerifiedPurchases({ userObjectId: user.objectId }));
    }
  }, [dispatch, user?.objectId, album?.objectId]);

  const imagesArray = useMemo(
    () => (print?.images ? Object.values(print?.images).map((type) => type.large) : []),
    [print?.images],
  );
  const mediumImagesArray = print?.images
    ? Object.values(print?.images).map((type) => type.medium)
    : [];
  const smallImagesArray = print?.images
    ? Object.values(print?.images).map(
        (type) => type.smallFramed || type.medium || type.original || type.large,
      ) //TODO: we dont have small images for p1, p2 ..
    : [];
  const price = print?.sellingInfo?.price;

  const currentImage = mediumImagesArray[hoveredIndex] || imagesArray[hoveredIndex];
  let thresholdDate = new Date();
  thresholdDate.setDate(thresholdDate.getDate() - 7);
  const publicationDate = new Date(album?.publicationDate);

  const handleShowImageBrowsingModal = () => {
    setShowImageBrowsingModal(true);
  };

  const onClickAlbumReview = useCallback(() => {
    if (isUserlogged()) {
      setShowAlbumReviewModal(true);
    }
  }, [isUserlogged, album?.objectId, print?.objectId]);

  const openYoutubeVideo = useCallback(() => {
    window.open(print?.youtubeUrl);
  }, [print?.youtubeUrl]);

  const renderTitleAndReviews = () => (
    <>
      <div className="d-flex align-items-center mb-2">
        <Link className="no-decoration" to={`/${serie?.permalink}/serie/${serie?.objectId}`}>
          <SerieTerminatedBadge isTerminated={serie?.isTerminated} title={serie?.title} />
        </Link>
        <div className="ms-2 badge text-black bb-bordered">
          Tome {album?.tome || 0}/{serie?.numberOfAlbums}
        </div>
        {!!album?.editorChoice && (
          <div className="ms-2">
            <EditorChoiceBadge article={album?.editorChoice} />
          </div>
        )}
      </div>
      <h1>{getFormattedTitle(serie, album)}</h1>
      <div className="d-flex flex-column flex-md-row align-items-md-center mt-md-n2">
        <div className="d-flex">
          <div className="d-inline-block">
            <StarLine size="medium" note={album?.note} classes="up-x-pix" />
            <span className="fw-bold pe-2 ps-2 bb-medium-small-text-size">
              {album?.note ? album?.note.toFixed(1) : ''}
            </span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <Link
            className="link-underline"
            to={`/${album?.permalink}/album/${album?.objectId}/avis`}
          >
            Voir les {album?.numberOfNotes} avis
          </Link>
          <div className="px-1">/</div>
          {!myAlbumReviews?.length && (
            <>
              <button
                onClick={onClickAlbumReview}
                className="btn btn-link mx-0 px-0 text-start d-flex"
              >
                <span className="d-block">Laisser un avis</span>
              </button>
              <RewardBadge
                className="ms-2"
                value={
                  isVerifiedPurchase
                    ? GENERAL.REWARD_PROGRAM.POINTS_FOR_NEW_REVIEW_WITH_VERIFIED_PURCHASE
                    : GENERAL.REWARD_PROGRAM.POINTS_FOR_NEW_REVIEW
                }
              />
            </>
          )}
          {myAlbumReviews?.length === 1 && (
            <Link to={`/${album?.permalink}/album/${album?.objectId}/avis`}>
              Mon avis: {myAlbumReviews[0].note} <Star className="mt-n1" percentFill="100%" />
            </Link>
          )}
          {myAlbumReviews?.length > 1 && (
            <Link to={`/${album?.permalink}/album/${album?.objectId}/avis`}>Mes avis</Link>
          )}
        </div>
      </div>
    </>
  );

  const carouselMobileDesktopArray = [...mediumImagesArray];
  if (!!print?.youtubeVideoId) carouselMobileDesktopArray.push('video-url');

  return (
    <div className="row px-sm-3 mt-n3">
      {showAlbumReviewModal && (
        <AddAlbumReviewModal
          show={showAlbumReviewModal}
          key={2}
          albumObjectId={album?.objectId}
          printObjectId={print?.objectId}
          initialNote={null}
          initialComment={null}
          reviewObjectId={null}
          callback={() => setShowAlbumReviewModal(false)}
        />
      )}
      {showImageBrowsingModal && (
        <ImageBrowsingModal
          show={showImageBrowsingModal}
          startIndex={hoveredIndex || 0}
          images={imagesArray || []}
          callback={() => setShowImageBrowsingModal(false)}
        />
      )}

      {/* LEFT IMAGES */}
      <div className="d-block d-md-none px-3 pt-3">{renderTitleAndReviews()}</div>
      <div className="col-lg-5 my-3">
        <div className="d-flex d-md-none flex-column align-items-center justify-content-center">
          <Carousel>
            {carouselMobileDesktopArray.map((data, index) => (
              <Carousel.Item key={`inner_${index}`}>
                {data === 'video-url' ? (
                  <div className="d-flex w-100">
                    <div className="d-flex flex-fill align-items-center justify-content-center">
                      <YoutubeEmbed videoId={print?.youtubeVideoId} height={225} />
                    </div>
                  </div>
                ) : (
                  <img src={data} className="d-block w-100" />
                )}
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        <div className="d-flex">
          <div className="d-none d-md-flex flex-column me-3 album-header-carousel w-25">
            {smallImagesArray.map((image, index) => (
              <div
                key={`carouselimg${image}`}
                className="mb-2"
                onMouseEnter={() => setHoveredIndex(index)}
              >
                <WithClickHandler onClick={() => handleShowImageBrowsingModal(true)}>
                  <Cover fullWidth imageUrl={image} />
                </WithClickHandler>
              </div>
            ))}
            {!!print?.youtubeVideoId && (
              <WithClickHandler onClick={openYoutubeVideo}>
                <div className="youtube-thumbnail">
                  <div className="overflow-hidden position-relative h-100">
                    <img
                      className="youtube-thumbnail-cover h-100"
                      alt="youtube"
                      src={smallImagesArray[0]}
                    />
                    <div className="youtube-thumbnail-play">
                      <Icon name="play" className="bb-medium-large-text-size" />
                    </div>
                  </div>
                </div>
              </WithClickHandler>
            )}
          </div>

          <div className="d-none d-md-flex w-75">
            <div>
              <div>
                <WithClickHandler onClick={handleShowImageBrowsingModal}>
                  <Cover fullWidth imageUrl={currentImage} />
                  {publicationDate > thresholdDate && (
                    <div className="bg-white rounded-bottom-end position-absolute album-header-release-container">
                      Sortie le{' '}
                      {bubbleUtils.date.formatDateWithFormat(album?.publicationDate, {
                        year: null,
                        month: '2-digit',
                      })}
                    </div>
                  )}
                </WithClickHandler>
              </div>

              {!!print?.youtubeVideoId && (
                <div className="pt-4">
                  <YoutubeEmbed videoId={print?.youtubeVideoId} />
                </div>
              )}
            </div>
          </div>

          {/* COLLECTION BUTTONS */}
          <span className="d-none d-md-flex">
            <CollectionActionsArray
              albumObjectId={album?.objectId}
              printObjectId={print?.objectId}
            />
          </span>
        </div>
        <span className="d-md-none">
          <CollectionActionsArray albumObjectId={album?.objectId} printObjectId={print?.objectId} />
        </span>
      </div>

      {/* RIGHT SECTION  */}
      <div className="col-lg-7 my-3 d-flex flex-column">
        <div className="d-none d-md-flex justify-content-between align-items-start px-1">
          <div>{renderTitleAndReviews()}</div>
          <AttachedPromotionCard albumObjectId={album?.objectId} />
        </div>

        <div className="d-block d-md-none">
          <AttachedPromotionCard albumObjectId={album?.objectId} />
        </div>

        <div className="bb-large-text-size fw-bold fw-md-normal my-2 px-1">
          {price !== null && bubbleUtils.currency.formatCurrency(Number(price || 0))}
        </div>

        {/* BUY ZONE */}
        <AlbumBuySection
          isOwned={isOwned}
          isInCart={isInCart}
          userObjectId={user.objectId}
          print={print}
        />
        <AlbumPrintsSection album={album} print={print} />
      </div>
    </div>
  );
};

AlbumHeader.propTypes = {
  album: PropTypes.shape({
    objectId: PropTypes.string,
    title: PropTypes.string,
    tome: PropTypes.number,
    summary: PropTypes.string,
    defaultSellingPrintObjectId: PropTypes.string,
    note: PropTypes.number,
    numberOfNotes: PropTypes.number,
  }),
  serie: PropTypes.shape({
    objectId: PropTypes.string,
    isTerminated: PropTypes.bool,
    numberOfAlbums: PropTypes.number,
  }),
  print: PropTypes.shape({
    images: PropTypes.object,
    hasSexualContent: PropTypes.bool,
  }),
};

export default AlbumHeader;
