import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { loadAlbum, loadAlbumSerie } from 'bubble-reducers/src/reducers/albums';
import { loadPrint } from 'bubble-reducers/src/reducers/prints';
import { getUserPrintForAlbum } from 'bubble-reducers/src/selectors';

import { getSeoForAlbum } from '@/services/seo-utils';

import BreadCrumbs from '@/components/Breadcrumbs/BreadCrumbs';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import CategoryRelatedArticles from '@/components/CategoryRelatedArticles/CategoryRelatedArticles';
import ItemReviews from '@/components/ItemReviews/ItemReviews';
import PromotionSectionWrapper from '@/components/PromotionSectionWrapper/PromotionSectionWrapper';
import RelatedArticles from '@/components/RelatedArticles/RelatedArticles';
import SerieSameGenre from '@/components/SerieSameGenre/SerieSameGenre';
import ShareZone from '@/components/ShareZone/ShareZone';

import AlbumDetails from './components/AlbumDetails';
import AlbumHeader from './components/AlbumHeader/AlbumHeader';
import OtherAlbumsInSerie from './components/OtherAlbumsInSerie';

const Album = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { albumObjectId, printObjectId } = useParams();

  const userObjectId = useSelector((state) => state.user.user?.objectId);
  const album = useSelector((state) => state.albums.albums[albumObjectId]);
  const serieObjectId = album?.serie?.objectId;
  const serie = useSelector((state) => state.series.series[serieObjectId]);

  // redirect to proper url with print when there isn't one
  if (!printObjectId && albumObjectId && album?.defaultPrintObjectId) {
    navigate(album?.defaultPrintObjectId);
  }

  const print = useSelector(
    (state) =>
      state.prints.prints[printObjectId] ||
      getUserPrintForAlbum(state, userObjectId, album?.defaultPrintObjectId, album?.objectId),
  );

  useEffect(() => {
    dispatch(loadAlbum({ albumObjectId }));
    dispatch(loadAlbumSerie({ albumObjectId }));
    if (printObjectId) {
      dispatch(loadPrint({ objectId: printObjectId }));
    }
  }, [albumObjectId, printObjectId]);

  const albumsInSerieCount = serie?.albums?.length;
  return (
    <div className="bb-background-light-grey">
      {!!album?.objectId && !!serie?.objectId && !!print?.objectId && (
        <BubbleHelmet seo={getSeoForAlbum(album, serie, print)} />
      )}
      <div className="nart-background-grey">
        <div className="container">
          <div className="d-flex align-items-center">
            <BreadCrumbs currentObject={album} />
            <ShareZone title="Faire découvrir cet album à des amis" />
          </div>
          <AlbumHeader album={album} serie={serie} print={print} />
        </div>
      </div>
      <div className="container">
        <AlbumDetails album={album} serie={serie} print={print} />
      </div>

      <div className="container">
        <div className="px-sm-3">
          <PromotionSectionWrapper albumObjectId={albumObjectId} />
        </div>
      </div>

      {/* ARTICLES TALKING ABOUT IT */}
      <RelatedArticles grey resourceObjectId={album?.objectId} />

      {/* REVIEWS */}
      <div className="nart-background-grey">
        <div className="container">
          <div className="px-sm-3">
            <ItemReviews albumObjectId={albumObjectId} serieObjectId={serieObjectId} />
          </div>
        </div>
      </div>

      {/* OTHER ALBUMS IN SERIE */}
      {!!serie && albumsInSerieCount > 1 && (
        <div className="container">
          <div className="px-sm-3">
            <OtherAlbumsInSerie album={album} serie={serie} />
          </div>
        </div>
      )}

      <div className="nart-background-grey">
        <div className="container">
          <div className="px-sm-3">
            <SerieSameGenre serie={serie} />
          </div>
        </div>
      </div>

      {/* OTHER ARTICLES TALKING ABOUT SAME CATEGORY */}
      <div className="container">
        <div className="px-sm-3">
          <CategoryRelatedArticles title={serie?.title} category={serie?.category} />
        </div>
      </div>
    </div>
  );
};

export default Album;
