import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import iconLibrary from '@/assets/icon_library_black.svg';

import HeaderDropdownButton from '../HeaderDropdownButton/HeaderDropdownButton';

import { LINK } from 'bubble-constants';

import './SubNavbar.scss';

const SubNavbar = () => {
  const userObjectId = useSelector((state) => state.user.user?.objectId);
  return (
    <nav className="d-none d-lg-flex align-items-center subnavbar navbar navbar-expand-lg bg-grey px-lg-5">
      <div className="collapse navbar-collapse" id="navbarNav">
        <div className="d-none d-lg-flex flex-row flex-fill align-items-center">
          <HeaderDropdownButton type="bd" />
          <HeaderDropdownButton type="comics" />
          <HeaderDropdownButton type="mangas" />
          <HeaderDropdownButton type="jeunesse" />
          <div className="pe-3">
            <Link
              className="no-decoration d-flex align-items-center px-3 text-pink"
              to="/promotions"
            >
              Promotions
            </Link>
          </div>
          <div style={{ width: 1, height: 20 }}>
            <div className="v-separator-no-gradient bg-secondary" />
          </div>

          <div className="ps-3">
            <Link className="no-decoration d-flex align-items-center px-3" to="/agenda">
              Nouveautés
            </Link>
          </div>

          <HeaderDropdownButton type="tops" />

          {/* <Link
            className="no-decoration d-flex align-items-center px-3 "
            to="/cartes-des-librairies-partenaires"
          >
            Librairies partenaires
          </Link> */}
          <a
            className="no-decoration px-3"
            target="_blank"
            rel="noopener noreferrer"
            href={LINK.BUBBLE_GOODIES_SHOP}
            style={{ paddingTop: '1px' }}
          >
            👕 👚 Goodies
          </a>
          {/* <Link className="no-decoration d-flex align-items-center px-3 text-pink" to="/en-stock">
            🎄 En stock
          </Link> */}

          <div className="flex-fill" />
          <div className="badge bg-white subnavbar-mes-bd-button-round me-4">
            <Link
              className="subnavbar-mes-bd-button no-decoration d-flex align-items-center px-3 text-center fw-bold text-black"
              to={userObjectId ? `/user/${userObjectId}/ma-collection` : '/login'}
            >
              <span>Mes BD</span>
              <img
                className="me-n2"
                style={{ position: 'relative', bottom: '2px', height: '25px' }}
                alt="add"
                src={iconLibrary}
              />
            </Link>
          </div>
        </div>
        <div className="d-block d-lg-none responsive-burger-menu">
          <ul className="navbar-nav">
            {userObjectId && (
              <li
                className="nav-item pt-3 pb-2"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
              >
                <Link
                  className="no-decoration px-3 fw-bold"
                  to={`/user/${userObjectId}/ma-collection`}
                >
                  Ma collection
                </Link>
              </li>
            )}
            <li
              className="nav-item pt-3 pb-2"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
            >
              <Link className="no-decoration px-3" to="/list?category=bd">
                Bandes dessinées
              </Link>
            </li>
            <li className="nav-item py-2" data-bs-toggle="collapse" data-bs-target="#navbarNav">
              <Link className="no-decoration px-3" to="/list?category=comics">
                Comics
              </Link>
            </li>
            <li className="nav-item py-2" data-bs-toggle="collapse" data-bs-target="#navbarNav">
              <Link className="no-decoration px-3" to="/list?category=mangas">
                Mangas
              </Link>
            </li>
            <li className="nav-item py-2" data-bs-toggle="collapse" data-bs-target="#navbarNav">
              <Link className="no-decoration px-3" to="/agenda">
                Nouveautés
              </Link>
            </li>
            <li className="nav-item py-2" data-bs-toggle="collapse" data-bs-target="#navbarNav">
              <Link className="no-decoration px-3" to="/tops">
                Tops
              </Link>
            </li>
            <li className="nav-item py-2" data-bs-toggle="collapse" data-bs-target="#navbarNav">
              <Link className="no-decoration px-3 text-bubble-color fw-bold" to="/promotions">
                Promotions
              </Link>
            </li>
            <li className="nav-item pt-2" data-bs-toggle="collapse" data-bs-target="#navbarNav">
              <Link
                className="no-decoration d-flex justify-content-center align-items-center text-white bg-bubble-color bubble-blog bg-cover"
                to="/9emeart"
              >
                <span role="img" aria-label="bubble" className="me-2">
                  📰
                </span>
                <span className="fw-bold">Le blog Bubble</span>&nbsp;- Toute l'actu BD, Comics et
                Mangas
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default SubNavbar;
