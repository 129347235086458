import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Icon } from '@/components/Icon/Icon';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import iconLibrary from '@/assets/icon_library_black.svg';
import logo from '@/assets/logo/full_horizontal.svg';

import { LINK } from 'bubble-constants';

import './BurgerMenu.scss';

export default function BurgerMenu({ show, onClose }) {
  const userObjectId = useSelector((state) => state.user.user?.objectId) || null;

  return (
    <div className={`burger-menu bg-blacker px-4${!!show ? ' show' : ''}`}>
      <div className="burger-menu-header d-flex align-items-center justify-content-between">
        <Link to="/" onClick={onClose}>
          <img className="bubble-logo" alt="img" src={logo} />
        </Link>
        <WithClickHandler onClick={onClose}>
          <Icon name="times" className="text-white bb-l-text-size" size={30} />
        </WithClickHandler>
      </div>
      <div className="h-separator nart-background-grey" />
      <div className="d-flex flex-column justify-content-center">
        <Link onClick={onClose} to="/9emeart" className="h3 text-center pt-5 pb-4">
          Trouver de l'inspiration
        </Link>
        <Link
          onClick={onClose}
          to="/list?category=bd"
          className="burger-menu-link-button bg-grey rounded-medium px-4 py-3 mb-3 mt-3"
        >
          <div className="h4 m-0">BD</div>
          <div>
            <Icon name="chevron-end" className="text-secondary bb-l-text-size" />
          </div>
        </Link>
        <Link
          onClick={onClose}
          to="/list?category=comics"
          className="burger-menu-link-button bg-grey rounded-medium px-4 py-3 mb-3"
        >
          <div className="h4 m-0">Comics</div>
          <div>
            <Icon name="chevron-end" className="text-secondary bb-l-text-size" />
          </div>
        </Link>
        <Link
          onClick={onClose}
          to="/list?category=mangas"
          className="burger-menu-link-button bg-grey rounded-medium px-4 py-3 mb-3"
        >
          <div className="h4 m-0">Mangas</div>
          <div>
            <Icon name="chevron-end" className="text-secondary bb-l-text-size" />
          </div>
        </Link>
        <Link
          onClick={onClose}
          to="/list?category=jeunesse"
          className="burger-menu-link-button bg-grey rounded-medium px-4 py-3 mb-3"
        >
          <div className="h4 m-0">Jeunesse</div>
          <div>
            <Icon name="chevron-end" className="text-secondary bb-l-text-size" />
          </div>
        </Link>
        <Link
          onClick={onClose}
          to="/promotions"
          className="text-pink burger-menu-link-button bg-grey rounded-medium px-4 py-3 mb-3"
        >
          <div className="h4 m-0">Promotions</div>
          <div>
            <Icon name="chevron-end" className="text-secondary bb-l-text-size" />
          </div>
        </Link>
      </div>

      <div className="h-separator nart-background-grey my-4" />

      <div className="d-flex flex-column align-items-center pb-5">
        <Link onClick={onClose} to="/agenda" className="h3 text-center pt-4">
          Nouveautés
        </Link>
        <Link onClick={onClose} to="/tops" className="h3 text-center pt-4">
          Tops & sélections
        </Link>
        {/* <Link
          onClick={onClose}
          to="/cartes-des-librairies-partenaires"
          className="h3 text-center pt-4"
        >
          Librairies partenaires
        </Link> */}
        <a
          className="h3 text-center pt-4"
          target="_blank"
          rel="noopener noreferrer"
          href={LINK.BUBBLE_GOODIES_SHOP}
        >
          👕 👚 Goodies
        </a>
        {/* <Link onClick={onClose} to="/en-stock" className="h3 text-pink text-center pt-4">
          🎄 En stock
        </Link> */}

        <div className="mt-4 d-flex flex-fill align-items-center justify-content-center badge bg-white subnavbar-mes-bd-button-round w-50">
          <Link
            onClick={onClose}
            className="subnavbar-mes-bd-button no-decoration d-flex align-items-center text-center fw-bold text-black"
            to={userObjectId ? `/user/${userObjectId}/ma-collection` : '/login'}
          >
            <span>Mes BD</span>
            <img className="ps-2 pb-1" alt="add" src={iconLibrary} />
          </Link>
        </div>
      </div>
    </div>
  );
}
