import PropTypes from 'prop-types';
import React from 'react';

import { getItemOnlineAvailability } from 'bubble-utils/src/album-utils';

import bubbleUtils from 'bubble-utils';

const ProductContent = (props) => {
  const { item } = props;
  const onlineAvailability = getItemOnlineAvailability(item);
  const estimatedDeliveryDate = onlineAvailability?.estimatedDeliveryDate;

  return (
    <div>
      <span className="fw-bold">
        {item.title}{' '}
        {item?.extra?.giftCard?.code ? (
          <span className="text-black">{item?.extra?.giftCard?.code}</span>
        ) : (
          ''
        )}
      </span>
      <div className="bb-medium-text-size">{item.description}</div>
      {!('noDate' in props) && estimatedDeliveryDate && (
        <div>
          Commandez maintenant pour le recevoir le{' '}
          {bubbleUtils.date.formatDateWithFormat(estimatedDeliveryDate, { year: null })} !
        </div>
      )}
    </div>
  );
};

ProductContent.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
  }),
  noDate: PropTypes.bool,
};

export default ProductContent;
