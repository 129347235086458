import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  activateInfinityCodeApi,
  addStoreToFavoriteApi,
  deleteUserApi,
  editUserApi,
  editUserNewsletterFromEmailApi,
  loadOrderApi,
  loadUserAdditionsApi,
  loadUserAddressesApi,
  loadUserApi,
  loadUserFavoriteStoresApi,
  loadUserLastAddedAlbumsApi,
  loadUserLikesApi,
  loadUserOrdersApi,
  loadUserPointsApi,
  loadUserPromoCodesApi,
  loadUserRevisionsApi,
  loadUserVerifiedPurchaseApi,
  loadUserVerifiedPurchasesApi,
  removeStoreFromFavoriteApi,
  uploadUserImageApi,
} from '../services/api';

import { storeStores } from '../reducers/stores';
import { getClientToken } from '../selectors';

const LOAD_USER = 'user/loadUser';
export const EDIT_USER = 'user/editUser';
const DELETE_USER = 'user/deleteUser';
const LOAD_USER_ORDER = 'user/loadUserOrder';
const LOAD_USER_ORDERS = 'user/loadUserOrders';
export const LOAD_USER_ADDRESSES = 'user/loadUserAddresses';
const LOAD_USER_FAVORITE_STORES = 'user/loadUserFavoriteStores';
const LOAD_USER_LAST_ADDED_ALBUMS = 'user/loadUserLastAddedAlbums';
const LOAD_USER_REVISIONS = 'user/loadUserRevisions';
const LOAD_USER_ADDITIONS = 'user/loadUserAdditions';
const LOAD_USER_PROMO_CODES = 'user/loadUserPromoCodes';
const LOAD_USER_LIKES = 'user/loadUserLikes';
const LOAD_USER_POINTS = 'user/loadUserPoints';
const LOAD_USER_VERIFIED_PURCHASE = 'user/loadUserVerifiedPurchase';
const LOAD_USER_VERIFIED_PURCHASES = 'user/loadUserVerifiedPurchases';
const UPLOAD_USER_IMAGE = 'user/uploadUserImage';
export const EDIT_USER_NEWSLETTER_FROM_EMAIL = 'user/editUserNewsletterFromEmail';
const ACTIVATE_INFINITY_CODE = 'user/activateInfinityCode';
const ADD_STORE_TO_FAVORITE = 'user/addStoreToFavorite';
const REMOVE_STORE_FROM_FAVORITE = 'user/removeStoreFromFavorite';

const initialState = {
  user: {},
  guest: {},
  otherUsers: {},
  orders: {},
  addresses: {},
  lastAddedAlbums: [],
  favoriteStoresIds: [],
  revisions: [],
  additions: [],
  promoCodes: [],
  likes: { reviews: [] },
  points: [],
  verifiedPurchasesMap: {},
  infinityCodeActivation: null,
  loading: {},
  errors: {},
};

export const loadUser = createAsyncThunk(LOAD_USER, async (params, { getState }) => {
  const userObjectId = params?.userObjectId || null;
  const isGuest = params?.isGuest || false;

  const clientToken = getClientToken(getState());
  const user = await loadUserApi(clientToken, userObjectId);
  return { user, isGuest };
});

export const editUser = createAsyncThunk(EDIT_USER, async (params, { getState }) => {
  const userObjectId = params?.userObjectId || null;
  const user = params?.user || false;

  const clientToken = getClientToken(getState());
  const updatedUser = await editUserApi(clientToken, userObjectId, user);
  return { user: updatedUser };
});

export const deleteUser = createAsyncThunk(DELETE_USER, async (params, { getState, dispatch }) => {
  const userObjectId = params?.userObjectId || null;

  const clientToken = getClientToken(getState());
  const deletedUser = await deleteUserApi(clientToken, userObjectId);
  return { user: deletedUser };
});

export const loadUserOrder = createAsyncThunk(LOAD_USER_ORDER, async (params, { getState }) => {
  const orderObjectId = params?.orderObjectId || null;

  const clientToken = getClientToken(getState());
  const order = await loadOrderApi(clientToken, orderObjectId);
  return { order };
});

export const loadUserOrders = createAsyncThunk(LOAD_USER_ORDERS, async (params, { getState }) => {
  const userObjectId = params?.userObjectId || null;

  const clientToken = getClientToken(getState());
  const orders = await loadUserOrdersApi(clientToken, userObjectId);
  return { orders };
});

export const loadUserAddresses = createAsyncThunk(
  LOAD_USER_ADDRESSES,
  async (params, { getState }) => {
    const userObjectId = params?.userObjectId || null;

    const clientToken = getClientToken(getState());
    const addresses = await loadUserAddressesApi(clientToken, userObjectId);
    return { addresses };
  },
);

export const loadUserFavoriteStores = createAsyncThunk(
  LOAD_USER_FAVORITE_STORES,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;

    const clientToken = getClientToken(getState());
    const stores = await loadUserFavoriteStoresApi(clientToken, userObjectId);
    dispatch(storeStores({ stores }));
    return { favoriteStores: stores.map((store) => store.objectId) };
  },
);

export const loadUserLastAddedAlbums = createAsyncThunk(
  LOAD_USER_LAST_ADDED_ALBUMS,
  async (params, { getState }) => {
    const userObjectId = params?.userObjectId || null;
    const limit = params?.limit || 10;

    const clientToken = getClientToken(getState());
    const lastAddedAlbums = await loadUserLastAddedAlbumsApi(clientToken, userObjectId, limit);
    return { lastAddedAlbums };
  },
);

export const loadUserRevisions = createAsyncThunk(
  LOAD_USER_REVISIONS,
  async (params, { getState }) => {
    const userObjectId = params?.userObjectId || null;

    const clientToken = getClientToken(getState());
    const revisions = await loadUserRevisionsApi(clientToken, userObjectId);
    return { revisions };
  },
);

export const loadUserAdditions = createAsyncThunk(
  LOAD_USER_ADDITIONS,
  async (params, { getState }) => {
    const userObjectId = params?.userObjectId || null;

    const clientToken = getClientToken(getState());
    const additions = await loadUserAdditionsApi(clientToken, userObjectId);
    return { additions };
  },
);

export const loadUserPromoCodes = createAsyncThunk(
  LOAD_USER_PROMO_CODES,
  async (params, { getState }) => {
    const userObjectId = params?.userObjectId || null;

    const clientToken = getClientToken(getState());
    const promoCodes = await loadUserPromoCodesApi(clientToken, userObjectId);
    return { promoCodes };
  },
);

export const loadUserLikes = createAsyncThunk(LOAD_USER_LIKES, async (params, { getState }) => {
  const userObjectId = params?.userObjectId || null;

  const clientToken = getClientToken(getState());
  const likes = await loadUserLikesApi(clientToken, userObjectId);
  return { likes };
});

export const loadUserPoints = createAsyncThunk(LOAD_USER_POINTS, async (params, { getState }) => {
  const userObjectId = params?.userObjectId || null;

  const clientToken = getClientToken(getState());
  const points = await loadUserPointsApi(clientToken, userObjectId);
  return { points };
});

export const loadUserVerifiedPurchase = createAsyncThunk(
  LOAD_USER_VERIFIED_PURCHASE,
  async (params, { getState }) => {
    const userObjectId = params?.userObjectId || null;
    const printObjectId = params?.printObjectId || null;

    const clientToken = getClientToken(getState());
    const verifiedPurchase = await loadUserVerifiedPurchaseApi(
      clientToken,
      userObjectId,
      printObjectId,
    );
    return { verifiedPurchase };
  },
);

export const loadUserVerifiedPurchases = createAsyncThunk(
  LOAD_USER_VERIFIED_PURCHASES,
  async (params, { getState }) => {
    const userObjectId = params?.userObjectId || null;

    const clientToken = getClientToken(getState());
    const verifiedPurchases = await loadUserVerifiedPurchasesApi(clientToken, userObjectId);
    return { verifiedPurchases };
  },
);

export const uploadUserImage = createAsyncThunk(UPLOAD_USER_IMAGE, async (params, { getState }) => {
  const userObjectId = params?.userObjectId || null;
  const formData = params?.formData || null;

  const clientToken = getClientToken(getState());
  const updatedUser = await uploadUserImageApi(clientToken, userObjectId, formData);
  dispatch(storeUser({ user: updatedUser }));
});

export const editUserNewsletterFromEmail = createAsyncThunk(
  EDIT_USER_NEWSLETTER_FROM_EMAIL,
  async (params, { getState }) => {
    const options = params?.options || null;

    const clientToken = getClientToken(getState());
    await editUserNewsletterFromEmailApi(clientToken, options);
  },
);

export const activateInfinityCode = createAsyncThunk(
  ACTIVATE_INFINITY_CODE,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;
    const code = params?.code || null;

    const clientToken = getClientToken(getState());
    const codeResult = await activateInfinityCodeApi(clientToken, userObjectId, code);
    dispatch(loadUser({ userObjectId }));
    return { codeResult };
  },
);

export const addStoreToFavorite = createAsyncThunk(
  ADD_STORE_TO_FAVORITE,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;
    const storeObjectId = params?.storeObjectId || null;

    const clientToken = getClientToken(getState());
    await addStoreToFavoriteApi(clientToken, userObjectId, storeObjectId);
    dispatch(loadUserFavoriteStores({ userObjectId }));
  },
);

export const removeStoreFromFavorite = createAsyncThunk(
  REMOVE_STORE_FROM_FAVORITE,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;
    const storeObjectId = params?.storeObjectId || null;

    const clientToken = getClientToken(getState());
    await removeStoreFromFavoriteApi(clientToken, userObjectId, storeObjectId);
    dispatch(loadUserFavoriteStores({ userObjectId }));
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserReducer: {
      reducer: (state, action) => initialState,
    },
    setUserObjectIdOnly: {
      reducer: (state, action) => {
        state.user = { objectId: action.payload.userObjectId };
      },
    },
    storeUser: {
      reducer: (state, action) => {
        state.user = action.payload.user;
      },
    },
    storeGuest: {
      reducer: (state, action) => {
        state.guest = action.payload.guest;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      // loadUser
      .addCase(loadUser.pending, (state, action) => {
        state.loading[LOAD_USER] = true;
        state.errors[LOAD_USER] = null;
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        const user = action.payload.user;
        if (!action.payload.isGuest) {
          state.user = user;
        } else {
          const newUsers = Object.assign({}, state.otherUsers);
          newUsers[user.objectId] = user;
          state.otherUsers = newUsers;
        }

        state.loading[LOAD_USER] = false;
        state.errors[LOAD_USER] = null;
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.loading[LOAD_USER] = false;
        state.errors[LOAD_USER] = action.error;
      })
      // editUser
      .addCase(editUser.pending, (state, action) => {
        state.loading[EDIT_USER] = true;
        state.errors[EDIT_USER] = null;
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.user = action.payload.user;

        state.loading[EDIT_USER] = false;
        state.errors[EDIT_USER] = null;
      })
      .addCase(editUser.rejected, (state, action) => {
        state.loading[EDIT_USER] = false;
        state.errors[EDIT_USER] = action.error;
      })
      // deleteUser
      .addCase(deleteUser.pending, (state, action) => {
        state.loading[DELETE_USER] = true;
        state.errors[DELETE_USER] = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.user = action.payload.user;

        state.loading[DELETE_USER] = false;
        state.errors[DELETE_USER] = null;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading[DELETE_USER] = false;
        state.errors[DELETE_USER] = action.error;
      })
      // loadUserOrder
      .addCase(loadUserOrder.pending, (state, action) => {
        state.loading[LOAD_USER_ORDER] = true;
        state.errors[LOAD_USER_ORDER] = null;
      })
      .addCase(loadUserOrder.fulfilled, (state, action) => {
        const orders = { ...state.orders };
        orders[action.payload.order.objectId] = action.payload.order;
        state.orders = orders;

        state.loading[LOAD_USER_ORDER] = false;
        state.errors[LOAD_USER_ORDER] = null;
      })
      .addCase(loadUserOrder.rejected, (state, action) => {
        state.loading[LOAD_USER_ORDER] = false;
        state.errors[LOAD_USER_ORDER] = action.error;
      })
      // loadUserOrders
      .addCase(loadUserOrders.pending, (state, action) => {
        state.loading[LOAD_USER_ORDERS] = true;
        state.errors[LOAD_USER_ORDERS] = null;
      })
      .addCase(loadUserOrders.fulfilled, (state, action) => {
        state.orders = action.payload.orders.reduce((acc, cur) => {
          acc[cur.objectId] = cur;
          return acc;
        }, {});

        state.loading[LOAD_USER_ORDERS] = false;
        state.errors[LOAD_USER_ORDERS] = null;
      })
      .addCase(loadUserOrders.rejected, (state, action) => {
        state.loading[LOAD_USER_ORDERS] = false;
        state.errors[LOAD_USER_ORDERS] = action.error;
      })
      // loadUserAddresses
      .addCase(loadUserAddresses.pending, (state, action) => {
        state.loading[LOAD_USER_ADDRESSES] = true;
        state.errors[LOAD_USER_ADDRESSES] = null;
      })
      .addCase(loadUserAddresses.fulfilled, (state, action) => {
        state.addresses = action.payload.addresses.reduce((acc, cur) => {
          acc[cur.objectId] = cur;
          return acc;
        }, {});

        state.loading[LOAD_USER_ADDRESSES] = false;
        state.errors[LOAD_USER_ADDRESSES] = null;
      })
      .addCase(loadUserAddresses.rejected, (state, action) => {
        state.loading[LOAD_USER_ADDRESSES] = false;
        state.errors[LOAD_USER_ADDRESSES] = action.error;
      })
      // loadUserFavoriteStores
      .addCase(loadUserFavoriteStores.pending, (state, action) => {
        state.loading[LOAD_USER_FAVORITE_STORES] = true;
        state.errors[LOAD_USER_FAVORITE_STORES] = null;
      })
      .addCase(loadUserFavoriteStores.fulfilled, (state, action) => {
        state.favoriteStoresIds = action.payload.favoriteStores;

        state.loading[LOAD_USER_FAVORITE_STORES] = false;
        state.errors[LOAD_USER_FAVORITE_STORES] = null;
      })
      .addCase(loadUserFavoriteStores.rejected, (state, action) => {
        state.loading[LOAD_USER_FAVORITE_STORES] = false;
        state.errors[LOAD_USER_FAVORITE_STORES] = action.error;
      })
      // loadUserLastAddedAlbums
      .addCase(loadUserLastAddedAlbums.pending, (state, action) => {
        state.loading[LOAD_USER_LAST_ADDED_ALBUMS] = true;
        state.errors[LOAD_USER_LAST_ADDED_ALBUMS] = null;
      })
      .addCase(loadUserLastAddedAlbums.fulfilled, (state, action) => {
        state.lastAddedAlbums = action.payload.lastAddedAlbums;

        state.loading[LOAD_USER_LAST_ADDED_ALBUMS] = false;
        state.errors[LOAD_USER_LAST_ADDED_ALBUMS] = null;
      })
      .addCase(loadUserLastAddedAlbums.rejected, (state, action) => {
        state.loading[LOAD_USER_LAST_ADDED_ALBUMS] = false;
        state.errors[LOAD_USER_LAST_ADDED_ALBUMS] = action.error;
      })
      // loadUserRevisions
      .addCase(loadUserRevisions.pending, (state, action) => {
        state.loading[LOAD_USER_REVISIONS] = true;
        state.errors[LOAD_USER_REVISIONS] = null;
      })
      .addCase(loadUserRevisions.fulfilled, (state, action) => {
        state.revisions = action.payload.revisions;

        state.loading[LOAD_USER_REVISIONS] = false;
        state.errors[LOAD_USER_REVISIONS] = null;
      })
      .addCase(loadUserRevisions.rejected, (state, action) => {
        state.loading[LOAD_USER_REVISIONS] = false;
        state.errors[LOAD_USER_REVISIONS] = action.error;
      })
      // loadUserAdditions
      .addCase(loadUserAdditions.pending, (state, action) => {
        state.loading[LOAD_USER_ADDITIONS] = true;
        state.errors[LOAD_USER_ADDITIONS] = null;
      })
      .addCase(loadUserAdditions.fulfilled, (state, action) => {
        state.additions = action.payload.additions;

        state.loading[LOAD_USER_ADDITIONS] = false;
        state.errors[LOAD_USER_ADDITIONS] = null;
      })
      .addCase(loadUserAdditions.rejected, (state, action) => {
        state.loading[LOAD_USER_ADDITIONS] = false;
        state.errors[LOAD_USER_ADDITIONS] = action.error;
      })
      // loadUserPromoCodes
      .addCase(loadUserPromoCodes.pending, (state, action) => {
        state.loading[LOAD_USER_PROMO_CODES] = true;
        state.errors[LOAD_USER_PROMO_CODES] = null;
      })
      .addCase(loadUserPromoCodes.fulfilled, (state, action) => {
        state.promoCodes = action.payload.promoCodes;

        state.loading[LOAD_USER_PROMO_CODES] = false;
        state.errors[LOAD_USER_PROMO_CODES] = null;
      })
      .addCase(loadUserPromoCodes.rejected, (state, action) => {
        state.loading[LOAD_USER_PROMO_CODES] = false;
        state.errors[LOAD_USER_PROMO_CODES] = action.error;
      })
      // loadUserLikes
      .addCase(loadUserLikes.pending, (state, action) => {
        state.loading[LOAD_USER_LIKES] = true;
        state.errors[LOAD_USER_LIKES] = null;
      })
      .addCase(loadUserLikes.fulfilled, (state, action) => {
        state.likes = action.payload.likes;

        state.loading[LOAD_USER_LIKES] = false;
        state.errors[LOAD_USER_LIKES] = null;
      })
      .addCase(loadUserLikes.rejected, (state, action) => {
        state.loading[LOAD_USER_LIKES] = false;
        state.errors[LOAD_USER_LIKES] = action.error;
      })
      // loadUserPoints
      .addCase(loadUserPoints.pending, (state, action) => {
        state.loading[LOAD_USER_POINTS] = true;
        state.errors[LOAD_USER_POINTS] = null;
      })
      .addCase(loadUserPoints.fulfilled, (state, action) => {
        state.points = action.payload.points;

        state.loading[LOAD_USER_POINTS] = false;
        state.errors[LOAD_USER_POINTS] = null;
      })
      .addCase(loadUserPoints.rejected, (state, action) => {
        state.loading[LOAD_USER_POINTS] = false;
        state.errors[LOAD_USER_POINTS] = action.error;
      })
      // loadUserVerifiedPurchase
      .addCase(loadUserVerifiedPurchase.pending, (state, action) => {
        state.loading[LOAD_USER_VERIFIED_PURCHASE] = true;
        state.errors[LOAD_USER_VERIFIED_PURCHASE] = null;
      })
      .addCase(loadUserVerifiedPurchase.fulfilled, (state, action) => {
        state.loading[LOAD_USER_VERIFIED_PURCHASE] = false;
        state.errors[LOAD_USER_VERIFIED_PURCHASE] = null;
      })
      .addCase(loadUserVerifiedPurchase.rejected, (state, action) => {
        state.loading[LOAD_USER_VERIFIED_PURCHASE] = false;
        state.errors[LOAD_USER_VERIFIED_PURCHASE] = action.error;
      })
      // loadUserVerifiedPurchases
      .addCase(loadUserVerifiedPurchases.pending, (state, action) => {
        state.loading[LOAD_USER_VERIFIED_PURCHASES] = true;
        state.errors[LOAD_USER_VERIFIED_PURCHASES] = null;
      })
      .addCase(loadUserVerifiedPurchases.fulfilled, (state, action) => {
        state.verifiedPurchasesMap = action.payload.verifiedPurchases.reduce((acc, cur) => {
          acc[cur.printObjectId] = true;
          return acc;
        }, {});

        state.loading[LOAD_USER_VERIFIED_PURCHASES] = false;
        state.errors[LOAD_USER_VERIFIED_PURCHASES] = null;
      })
      .addCase(loadUserVerifiedPurchases.rejected, (state, action) => {
        state.loading[LOAD_USER_VERIFIED_PURCHASES] = false;
        state.errors[LOAD_USER_VERIFIED_PURCHASES] = action.error;
      })
      // uploadUserImage
      .addCase(uploadUserImage.pending, (state, action) => {
        state.loading[UPLOAD_USER_IMAGE] = true;
        state.errors[UPLOAD_USER_IMAGE] = null;
      })
      .addCase(uploadUserImage.fulfilled, (state, action) => {
        state.loading[UPLOAD_USER_IMAGE] = false;
        state.errors[UPLOAD_USER_IMAGE] = null;
      })
      .addCase(uploadUserImage.rejected, (state, action) => {
        state.loading[UPLOAD_USER_IMAGE] = false;
        state.errors[UPLOAD_USER_IMAGE] = action.error;
      })
      // editUserNewsletterFromEmail
      .addCase(editUserNewsletterFromEmail.pending, (state, action) => {
        state.loading[EDIT_USER_NEWSLETTER_FROM_EMAIL] = true;
        state.errors[EDIT_USER_NEWSLETTER_FROM_EMAIL] = null;
      })
      .addCase(editUserNewsletterFromEmail.fulfilled, (state, action) => {
        state.loading[EDIT_USER_NEWSLETTER_FROM_EMAIL] = false;
        state.errors[EDIT_USER_NEWSLETTER_FROM_EMAIL] = null;
      })
      .addCase(editUserNewsletterFromEmail.rejected, (state, action) => {
        state.loading[EDIT_USER_NEWSLETTER_FROM_EMAIL] = false;
        state.errors[EDIT_USER_NEWSLETTER_FROM_EMAIL] = action.error;
      })
      // activateInfinityCode
      .addCase(activateInfinityCode.pending, (state, action) => {
        state.infinityCodeActivation = null;

        state.loading[ACTIVATE_INFINITY_CODE] = true;
        state.errors[ACTIVATE_INFINITY_CODE] = null;
      })
      .addCase(activateInfinityCode.fulfilled, (state, action) => {
        state.infinityCodeActivation = action.payload.codeResult;

        state.loading[ACTIVATE_INFINITY_CODE] = false;
        state.errors[ACTIVATE_INFINITY_CODE] = null;
      })
      .addCase(activateInfinityCode.rejected, (state, action) => {
        state.infinityCodeActivation = null;

        state.loading[ACTIVATE_INFINITY_CODE] = false;
        state.errors[ACTIVATE_INFINITY_CODE] = action.error;
      })
      // addStoreToFavorite
      .addCase(addStoreToFavorite.pending, (state, action) => {
        state.loading[ADD_STORE_TO_FAVORITE] = true;
        state.errors[ADD_STORE_TO_FAVORITE] = null;
      })
      .addCase(addStoreToFavorite.fulfilled, (state, action) => {
        state.loading[ADD_STORE_TO_FAVORITE] = false;
        state.errors[ADD_STORE_TO_FAVORITE] = null;
      })
      .addCase(addStoreToFavorite.rejected, (state, action) => {
        state.loading[ADD_STORE_TO_FAVORITE] = false;
        state.errors[ADD_STORE_TO_FAVORITE] = action.error;
      })
      // removeStoreFromFavorite
      .addCase(removeStoreFromFavorite.pending, (state, action) => {
        state.loading[REMOVE_STORE_FROM_FAVORITE] = true;
        state.errors[REMOVE_STORE_FROM_FAVORITE] = null;
      })
      .addCase(removeStoreFromFavorite.fulfilled, (state, action) => {
        state.loading[REMOVE_STORE_FROM_FAVORITE] = false;
        state.errors[REMOVE_STORE_FROM_FAVORITE] = null;
      })
      .addCase(removeStoreFromFavorite.rejected, (state, action) => {
        state.loading[REMOVE_STORE_FROM_FAVORITE] = false;
        state.errors[REMOVE_STORE_FROM_FAVORITE] = action.error;
      });
  },
});

export default userSlice.reducer;

export const { resetUserReducer, storeUser, storeGuest, setUserObjectIdOnly } = userSlice.actions;
