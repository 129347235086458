import config from 'bubble-config';

import { isSendBeaconAvailable } from 'bubble-utils/src/env-utils';
import { buildQueryString, buildSortedQueryString } from 'bubble-utils/src/string-utils';

const handleFetchResponseErrors = (response) =>
  // A fetch() promise only rejects when a network error is encountered (which is usually when
  // there's a permissions issue or similar). A fetch() promise does not reject on HTTP errors
  // (404, etc.). Instead, a then handler must check the Response.ok and/or Response.status
  // properties. https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
  new Promise((resolve, reject) => {
    // we do use `Promise.resolve(response.json())` because `response.json()` can be just a function
    // (not a `Promise`)
    return response.ok
      ? Promise.resolve(response.json()).then((json) => resolve(json))
      : Promise.resolve(response.json()).then((json) => reject(json.error || json.message));
  });

const makeApiRequest = async (
  method,
  url,
  client,
  body,
  contentType = { 'Content-Type': 'application/json' },
  preventStringify = false,
) => {
  const response = await fetch(`${config.bubbleApi.host}${url}`, {
    method: method,
    headers: { ...contentType, Authorization: client || undefined },
    body: body ? (preventStringify ? body : JSON.stringify(body)) : null,
  });
  return await handleFetchResponseErrors(response);
};

const makeApiBeaconRequest = (url, body) => {
  navigator.sendBeacon(`${config.bubbleApi.host}${url}`, JSON.stringify(body));
};

export const updateLibraryApi = (clientToken, userObjectId, requestBody) =>
  makeApiRequest('PUT', `/v1.3/libraries/${userObjectId}`, clientToken, requestBody);

export const loadPrintApi = (clientToken, printObjectId, queryString) =>
  makeApiRequest('GET', `/v1/prints/${printObjectId}${queryString}`, clientToken);

export const loadArticleCommentsApi = (client, articleObjectId) =>
  makeApiRequest('GET', `/v1/articles/${articleObjectId}/comments`, client);

export const storeCommentApi = (client, articleObjectId, content) =>
  makeApiRequest('POST', `/v1/comments`, client, { articleObjectId, content });

export const deleteCommentApi = (client, commentObjectId) =>
  makeApiRequest('DELETE', `/v1/comments/${commentObjectId}`, client);

export const updateCommentApi = (client, commentObjectId, comment) =>
  makeApiRequest('PUT', `/v1/comments/${commentObjectId}`, client, { comment });

export const flagCommentApi = (client, commentObjectId) =>
  makeApiRequest('POST', `/v1/comments/${commentObjectId}/flag`, client);

export const loadHighlightedResourcesApi = (client) =>
  makeApiRequest('GET', `/v1.4/options/highlights`, client);

export const loadUserAgendaApi = (client, libraryId, months) => {
  return makeApiRequest(
    'GET',
    `/v1.4/libraries/${libraryId}/agenda${months ? '?monthsPrior=' + months : ''}`,
    client,
  );
};

export const loadAppOptionsApi = (client, buildNumber) =>
  makeApiRequest('GET', `/v1/app-options${buildNumber ? '?build=' + buildNumber : ''}`, client);

export const updateAppOptionsApi = (client, fieldsAndData) =>
  makeApiRequest('PUT', `/v1/app-options`, client, fieldsAndData);

export const loadBannersApi = (client) => makeApiRequest('GET', `/v1/banners`, client);

export const loadIapsApi = (client) => makeApiRequest('GET', `/v1.3/in-app-purchases`, client);

export const loadSignedAlbumsApi = (client, libraryObjectId) =>
  makeApiRequest('GET', `/v1/users/${libraryObjectId}/signed-albums`, client);

export const editAddressApi = (client, addressObjectId, address) =>
  makeApiRequest('PUT', `/v1/addresses/${addressObjectId}`, client, address);

export const deleteAddressApi = (client, addressObjectId) =>
  makeApiRequest('DELETE', `/v1/addresses/${addressObjectId}`, client);

export const loadLoanedAlbumsApi = (client, userObjectId) =>
  makeApiRequest('GET', `/v1/users/${userObjectId}/loans`, client);

export const loadWishlistAlbumsApi = (client, libraryObjectId) =>
  makeApiRequest('GET', `/v1.3/libraries/${libraryObjectId}/wishlist`, client);

// To unplug
export const updateWishlistAlbumsApi = (client, libraryObjectId, albumIds, wishlistObject) =>
  makeApiRequest(
    'PUT',
    `/v1.3/libraries/${libraryObjectId}/wishlist/${albumIds.join(',')}`,
    client,
    wishlistObject,
  );

export const loadLibraryApi = (client, libraryObjectId) =>
  makeApiRequest('GET', `/v1.4/libraries/${libraryObjectId}`, client);

export const loadLibraryPrintsOptionsApi = (client, libraryObjectId) =>
  makeApiRequest('GET', `/v1.4/libraries/${libraryObjectId}/prints`, client);

export const loadFollowedApi = (client, libraryObjectId) =>
  makeApiRequest('GET', `/v1.4/libraries/${libraryObjectId}/follow`, client);

export const uploadUserImageApi = (client, userObjectId, formData) =>
  makeApiRequest(
    'POST',
    `/v1/users/${userObjectId}/upload-image`,
    client,
    formData,
    { 'Content-Type': 'multipart/form-data' },
    true,
  );

export const addSerieToFollowApi = (client, libraryObjectId, serieObjectId) =>
  makeApiRequest('POST', `/v1/libraries/${libraryObjectId}/follow/${serieObjectId}`, client);

export const removeSerieFromFollowApi = (client, libraryObjectId, serieObjectId) =>
  makeApiRequest('DELETE', `/v1/libraries/${libraryObjectId}/follow/${serieObjectId}`, client);

export const loadAlbumApi = (client, albumObjectId) =>
  makeApiRequest('GET', `/v1.3/albums/${albumObjectId}`, client);

export const loadAlbumSerieApi = (client, albumObjectId) =>
  makeApiRequest('GET', `/v1.3/albums/${albumObjectId}/series`, client);

export const loadArticlesApi = (client, options) =>
  makeApiRequest('GET', `/v1.4/articles${buildSortedQueryString(options)}`, client);

export const loadArticleApi = (client, articleObjectId, options) =>
  makeApiRequest(
    'GET',
    `/v1.4/articles/${articleObjectId}${options ? buildQueryString(options) : ''}`,
    client,
  );

export const loadArticleResourcesApi = (client, articleObjectId) =>
  makeApiRequest('GET', `/v1.3/articles/${articleObjectId}/resources`, client);

export const loadArticlesForResourceApi = (client, resourceObjectId) =>
  makeApiRequest('GET', `/v1.4/articles/resources/${resourceObjectId}`, client);

export const loadRelatedArticlesApi = (client, articleObjectId) =>
  makeApiRequest('GET', `/v1.4/articles/${articleObjectId}/related`, client);

export const loadHomeArticlesApi = (client, category) =>
  makeApiRequest('GET', `/v1.4/articles?home=1${category ? `&category=${category}` : ''}`, client);

export const loadAgendasApi = (client, daysPrior = null, start = null) => {
  const options = { daysPrior, start: start ? start.toISOString() : null };
  if (!options.daysPrior) delete options.daysPrior;
  if (!options.start) delete options.start;
  const hasParams = 'daysPrior' in options || 'start' in options ? '?' : '';
  return makeApiRequest(
    'GET',
    `/v1.3/agendas${hasParams}${Object.keys(options)
      .map((key) => (options[key] ? `${key}=${options[key]}` : null))
      .filter((row) => row)
      .join('&')}`,
    client,
  );
};

export const addEventApi = (client, events) =>
  isSendBeaconAvailable
    ? makeApiBeaconRequest(`/v1.1/events`, events)
    : makeApiRequest('POST', `/v1/events`, client, events);

export const loadAppReviewsApi = (client) => makeApiRequest('GET', `/v1/app-reviews`, client);

export const loadReviewApi = (client, reviewObjectId) =>
  makeApiRequest('GET', `/v1/reviews/${reviewObjectId}`, client);

export const addReviewApi = (client, review) =>
  makeApiRequest('POST', `/v1/reviews`, client, review);

export const editReviewApi = (client, reviewObjectId, review) =>
  makeApiRequest('PUT', `/v1/reviews/${reviewObjectId}`, client, review);

export const loadSerieReviewsApi = (client, serieObjectId) =>
  makeApiRequest('GET', `/v1/series/${serieObjectId}/reviews`, client);

export const loadMyReviewForSerieApi = (client, userObjectId, serieObjectId) =>
  makeApiRequest('GET', `/v1/users/${userObjectId}/series/${serieObjectId}/reviews`, client);

export const loadAlbumReviewsApi = (client, albumObjectId) =>
  makeApiRequest('GET', `/v1/albums/${albumObjectId}/reviews`, client);

export const loadMyReviewsForAlbumApi = (client, userObjectId, albumObjectId) =>
  makeApiRequest('GET', `/v1.3/users/${userObjectId}/albums/${albumObjectId}/reviews`, client);

export const loadTopsApi = (client, withData) =>
  makeApiRequest('GET', `/v1.1/tops${withData ? '?with_data=1' : ''}`, client);

export const loadTopApi = (client, topName) =>
  makeApiRequest('GET', `/v1.3/tops/${topName}`, client);

export const loadSerieApi = (client, serieObjectId, options = null) =>
  makeApiRequest(
    'GET',
    `/v1.3/series/${serieObjectId}${options ? buildQueryString(options) : ''}`,
    client,
  );

export const createSerieRevisionApi = (client, serieObjectId, revision) =>
  makeApiRequest('POST', `/v1/series/${serieObjectId}/series-revisions`, client, revision);

export const createAuthorRevisionApi = (client, authorObjectId, revision) =>
  makeApiRequest('POST', `/v1/authors/${authorObjectId}/authors-revisions`, client, revision);

export const createAlbumRevisionApi = (client, albumObjectId, revision) =>
  makeApiRequest('POST', `/v1/albums/${albumObjectId}/album-revisions`, client, revision);

export const createPrintRevisionApi = (client, printObjectId, revision) =>
  makeApiRequest('POST', `/v1/prints/${printObjectId}/print-revisions`, client, revision);

export const createAlbumAdditionApi = (client, userObjectId, userEmail, addition) =>
  makeApiRequest('POST', `/v1/additions/`, client, {
    ean: addition.ean,
    userId: userObjectId,
    userEmail: userEmail,
    print: addition,
    isResubmission: addition.isResubmission,
  });

export const checkEanStatusApi = (client, ean) =>
  makeApiRequest('GET', `/v1.1/check-eans/${ean}`, client);

export const loadCartApi = (client, userObjectId, params) =>
  makeApiRequest('GET', `/v1.3/carts/${userObjectId}?should_include_reward_program=1`, client);

export const updateCartApi = (client, userObjectId, options) =>
  makeApiRequest(
    'PUT',
    `/v1.3/carts/${userObjectId}?should_include_reward_program=1`,
    client,
    options,
  );

export const loadUserApi = (client, userObjectId) =>
  makeApiRequest('GET', `/v1/users/${userObjectId}`, client);

export const editUserApi = (client, userObjectId, user) =>
  makeApiRequest('PUT', `/v1/users/${userObjectId}`, client, {
    ...user,
  });
export const deleteUserApi = (client, userObjectId) =>
  makeApiRequest('DELETE', `/v1/users/${userObjectId}`, client);

export const loadUserOrdersApi = (client, userObjectId) =>
  makeApiRequest('GET', `/v1.3/users/${userObjectId}/orders`, client);

export const loadUserAddressesApi = (client, userObjectId) =>
  makeApiRequest('GET', `/v1/users/${userObjectId}/addresses`, client);

export const loadUserRevisionsApi = (client, userObjectId) =>
  makeApiRequest('GET', `/v1/users/${userObjectId}/revisions`, client);

export const loadUserAdditionsApi = (client, userObjectId) =>
  makeApiRequest('GET', `/v1/users/${userObjectId}/additions`, client);

export const loadUserPromoCodesApi = (client, userObjectId) =>
  makeApiRequest('GET', `/v1/users/${userObjectId}/promo-codes`, client);

export const loadUserPointsApi = (client, userObjectId) =>
  makeApiRequest('GET', `/v1/users/${userObjectId}/points`, client);

export const loadUserVerifiedPurchaseApi = (client, userObjectId, printObjectId) =>
  makeApiRequest('GET', `/v1/users/${userObjectId}/verified-purchases/${printObjectId}`, client);

export const loadUserVerifiedPurchasesApi = (client, userObjectId) =>
  makeApiRequest('GET', `/v1/users/${userObjectId}/verified-purchases`, client);

export const loadUserFavoriteStoresApi = (client, userObjectId) =>
  makeApiRequest('GET', `/v1/users/${userObjectId}/store`, client);

export const loadUserStatsApi = (client, userObjectId) =>
  makeApiRequest('GET', `/v1.3/users/${userObjectId}/stats`, client);

export const loadPaymentMethodsApi = (client, userObjectId) =>
  makeApiRequest('GET', `/v1/users/${userObjectId}/payment-methods`, client);

export const updatePaymentMethodApi = (client, userObjectId, paymentMethod) =>
  makeApiRequest('PUT', `/v1/users/${userObjectId}/payment-methods`, client, paymentMethod);

export const buyIapApi = (client, userObjectId, productId) =>
  makeApiRequest('POST', `/v1/users/${userObjectId}/in-app-purchases`, client, { productId });

export const updateIapPaymentIntentApi = (client, userObjectId, productId, paymentId) =>
  makeApiRequest('PUT', `/v1/users/${userObjectId}/in-app-purchases/${paymentId}`, client, {
    productId,
  });

export const loadAuthorApi = (client, authorObjectId) =>
  makeApiRequest('GET', `/v1/authors/${authorObjectId}`, client);

export const loadAuthorsAtAlphanumericIndexApi = (client, index) =>
  makeApiRequest('GET', `/v1/authors/index/${index}`, client);

export const loadAuthorTagsApi = (client, authorObjectId) =>
  makeApiRequest('GET', `/v1/authors/${authorObjectId}/tags`, client);

export const loadStoreApi = (client, storeObjectId) =>
  makeApiRequest('GET', `/v1/stores/${storeObjectId}`, client);

export const createStorePartnershipRequestApi = (client, storeObjectId, userObjectId) =>
  makeApiRequest('POST', `/v1/stores/${storeObjectId}/partnership-requests`, client, {
    userObjectId,
  });

export const loadUserLastAddedAlbumsApi = (client, userObjectId, limit) =>
  makeApiRequest('GET', `/v1.3/libraries/${userObjectId}/last-added?limit=${limit}`, client);

export const createOrderApi = (client, order) =>
  makeApiRequest('POST', `/v1.3/orders`, client, order);

export const updateOrderApi = (client, orderObjectId, order) =>
  makeApiRequest('PUT', `/v1.3/orders/${orderObjectId}`, client, order);

export const loadOrderApi = (client, orderObjectId) =>
  makeApiRequest('GET', `/v1.3/orders/${orderObjectId}`, client);

export const loadCountriesApi = (client) => makeApiRequest('GET', `/v1/countries`, client);

export const loadAdsApi = (client) => makeApiRequest('GET', `/v1/ads`, client);

export const loadNewsApi = (client) => makeApiRequest('GET', `/v1/news`, client);

export const loadShippingFeesApi = (client) => makeApiRequest('GET', `/v1/shipping-fees`, client);

export const loadProductsApi = (client, params) =>
  makeApiRequest('GET', `/v1/products${buildQueryString(params)}`, client);

export const addStoreToFavoriteApi = (client, userObjectId, storeObjectId) =>
  makeApiRequest('POST', `/v1/users/${userObjectId}/store/${storeObjectId}`, client);

export const removeStoreFromFavoriteApi = (client, userObjectId, storeObjectId) =>
  makeApiRequest('DELETE', `/v1/users/${userObjectId}/store/${storeObjectId}`, client);

export const loadRelatedAuthorsApi = (client, authorObjectId) =>
  makeApiRequest('GET', `/v1/authors/${authorObjectId}/related`, client);

export const checkStocksForEansApi = (client, eans, storeObjectId = null) =>
  makeApiRequest(
    'GET',
    `/v1/stocks/${eans.join(',')}${storeObjectId ? `?sid=${storeObjectId}` : ''}`,
    client,
  );

export const loadStoreBestSellersApi = (client, storeObjectId) =>
  makeApiRequest('GET', `/v1/stores/${storeObjectId}/best-sellers`, client);

export const loadStoresApi = (client) => makeApiRequest('GET', `/v1/stores`, client);

export const loadGenresApi = (client) => makeApiRequest('GET', `/v1/genres`, client);

export const loadTypesApi = (client) => makeApiRequest('GET', `/v1/types`, client);

export const loadCollectionsApi = (client) => makeApiRequest('GET', `/v1/collections`, client);

export const loadCategoriesApi = (client) => makeApiRequest('GET', `/v1/categories`, client);

export const createAddressApi = (client, address) =>
  makeApiRequest('POST', `/v1/addresses`, client, address);

export const loadSeriesApi = (client, options) =>
  makeApiRequest('GET', `/v1.3/series${buildQueryString(options)}`, client);

export const loadSeriesAtAlphanumericIndexApi = (client, index) =>
  makeApiRequest('GET', `/v1.3/series/index/${index}`, client);

export const searchRelaysApi = (client, options) =>
  makeApiRequest('GET', `/v1.3/relays${buildQueryString(options)}`, client);

export const searchCollectionsApi = (client, queryString) =>
  makeApiRequest('GET', `/v1/collections${queryString}`, client);

export const searchTypesApi = (client, queryString) =>
  makeApiRequest('GET', `/v1/types${queryString}`, client);

export const loadAlbumsApi = (client, options) =>
  makeApiRequest('GET', `/v1.3/albums${buildSortedQueryString(options)}`, client);

export const loadAlbumsV2Api = (client, options) =>
  makeApiRequest('GET', `/v1.4/albums${buildSortedQueryString(options)}`, client);

export const loadRecommendationApi = (client, libraryObjectId) =>
  libraryObjectId
    ? makeApiRequest('GET', `/v1.3/libraries/${libraryObjectId}/reco`, client)
    : makeApiRequest('GET', `/v1.3/reco`, client);

export const activateInfinityCodeApi = (client, userObjectId, code) =>
  makeApiRequest('POST', `/v1/users/${userObjectId}/premium`, client, { code });

export const signupApi = (
  username,
  password,
  hasAcceptedNewsletter,
  isGdprCompliant,
  token = null,
  platform,
  options,
) =>
  makeApiRequest('POST', `/v1/auth/signup`, null, {
    username,
    password,
    hasAcceptedNewsletter,
    isGdprCompliant,
    token,
    platform,
    options,
  });

export const loginApi = (username, password, platform, options) =>
  makeApiRequest('POST', `/v1/auth/signin`, null, { username, password, platform, options });

export const loginGuestApi = (username, platform, options) =>
  makeApiRequest('POST', `/v1/auth/signin`, null, { username, isGuest: true, platform, options });

export const loginWithFacebookApi = (
  accessToken,
  expirationTime,
  method,
  platform,
  referralCode = null,
) =>
  makeApiRequest(
    'GET',
    `/v1/auth/facebook/token?access_token=${accessToken}&expiration_time=${expirationTime}&method=${method}&platform=${platform}${
      referralCode ? `&referralCode=${referralCode}` : ''
    }`,
    null,
  );

export const loginWithFacebookJwtTokenApi = (
  authenticationToken,
  method,
  platform,
  nonce,
  referralCode = null,
) =>
  makeApiRequest(
    'GET',
    `/v1/auth/facebook/jwt-token?authentication_token=${authenticationToken}&method=${method}&platform=${platform}&nonce=${nonce}${
      referralCode ? `&referralCode=${referralCode}` : ''
    }`,
    null,
  );

export const loginWithAppleApi = (authentificationData, platform, referralCode = null) =>
  makeApiRequest('POST', '/v1/auth/apple/token', null, {
    ...authentificationData,
    platform,
    referralCode,
  });

export const checkJwtTokenValidityApi = (client) =>
  makeApiRequest('GET', '/v1/auth/token-validity', client);

export const resetPasswordApi = (email) =>
  makeApiRequest('POST', '/v1/auth/forgot', null, { username: email });

export const updatePasswordVerifyTokenApi = (email, token) =>
  makeApiRequest('GET', `/v1/auth/reset${buildQueryString({ email, token })}`);

export const updatePasswordFromEmailApi = (email, token, newPassword) =>
  makeApiRequest('POST', '/v1/auth/reset', null, { email, token, newPassword });

export const updatePasswordApi = (client, userObjectId, newPassword) =>
  makeApiRequest('PUT', '/v1/auth/update', client, { userObjectId, newPassword });

export const loadMyReviewsApi = (client, userObjectId) =>
  makeApiRequest('GET', `/v1/users/${userObjectId}/reviews`, client);

export const addLikeApi = (client, reviewLike) =>
  makeApiRequest('POST', '/v1/likes', client, reviewLike);

export const deleteLikeApi = (client, reviewLike) =>
  makeApiRequest('DELETE', '/v1/likes', client, reviewLike);

export const loadUserLikesApi = (client, userObjectId) =>
  makeApiRequest('GET', `/v1/users/${userObjectId}/likes`, client);

export const loadSelectedAlbumPrintsApi = (client, userObjectId) =>
  makeApiRequest('GET', `/v1/users/${userObjectId}/selected-prints`, client);

export const updateAlbumTagsApi = (client, userObjectId, albumObjectId, tags, forcedWeight) =>
  makeApiRequest('PUT', `/v1/albums/${albumObjectId}`, client, {
    tags,
    userObjectId,
    forcedWeight,
  });

export const loadUserTagsApi = (client, userObjectId) =>
  makeApiRequest('GET', `/v1/users/${userObjectId}/tags`, client);

export const loadTagsApi = (client) => makeApiRequest('GET', `/v1/tags`, client);

export const loadTagApi = (client, tagObjectId) =>
  makeApiRequest('GET', `/v1/tags/${tagObjectId}`, client);

export const updateTagApi = (client, tagObjectId, tag) =>
  makeApiRequest('PUT', `/v1/tags/${tagObjectId}`, client, { tag });

export const deleteTagApi = (client, tagObjectId) =>
  makeApiRequest('DELETE', `/v1/tags/${tagObjectId}`, client);

export const deleteItemTagApi = (client, itemTagObjectId) =>
  makeApiRequest('DELETE', `/v1/item-tags/${itemTagObjectId}`, client);

export const updateItemTagApi = (client, itemTagObjectId, itemTag) =>
  makeApiRequest('PUT', `/v1/item-tags/${itemTagObjectId}`, client, { itemTag });

export const loadCrowdfundingApi = (client, crowdfundingProjectNameKey) =>
  makeApiRequest('GET', `/v1/crowdfundings/${crowdfundingProjectNameKey}`, client);

export const loadPromotionApi = (client, promotionObjectId) =>
  makeApiRequest('GET', `/v1/promotions/${promotionObjectId}`, client);

export const loadPromotionsApi = (client, paramsString) =>
  makeApiRequest('GET', `/v1/promotions${paramsString || ''}`, client);

export const editUserNewsletterFromEmailApi = (client, params) =>
  makeApiRequest('PUT', `/v1/users/newsletter`, client, params);

export const addAuthorToFollowApi = async (client, userObjectId, authorObjectId) =>
  makeApiRequest(
    'POST',
    `/v1.4/libraries/${userObjectId}/authors/follow/${authorObjectId}`,
    client,
  );

export const removeAuthorFromFollowApi = async (client, userObjectId, authorObjectId) =>
  makeApiRequest(
    'DELETE',
    `/v1.4/libraries/${userObjectId}/authors/follow/${authorObjectId}`,
    client,
  );

export const loadLibraryAuthorFollowApi = async (client, userObjectId) =>
  makeApiRequest('GET', `/v1.4/libraries/${userObjectId}/authors/follow`, client);

export const loadAvailabilityAlertsApi = async (client, userObjectId) =>
  makeApiRequest('GET', `/v1/users/${userObjectId}/availability-alerts`, client);

export const createAvailabilityAlertApi = async (client, userObjectId, printObjectId) =>
  makeApiRequest('POST', `/v1/users/${userObjectId}/availability-alerts`, client, {
    printObjectId,
  });

export const deleteAvailabilityAlertApi = async (client, userObjectId, availabilityAlertObjectId) =>
  makeApiRequest(
    'DELETE',
    `/v1/users/${userObjectId}/availability-alerts/${availabilityAlertObjectId}`,
    client,
  );
