import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { loadPrint } from 'bubble-reducers/src/reducers/prints';
import { isPrintInWishlist, isPrintOwned, makeIsAlbumInCart } from 'bubble-reducers/src/selectors';

import { compressAuthorName } from 'bubble-utils/src/author-utils';

import {
  useAddPrintsToWishlist,
  useRemovePrintsFromWishlist,
} from '@/services/hooks/useLibraryUtils';
import { getAssetImage } from '@/services/image-utils';

import Cover from '@/components/Cover/Cover';
import StarLine from '@/components/StarLine/StarLine';
import Tooltip from '@/components/Tooltip/Tooltip';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import AddToCartZone from './components/AddToCartZone';
import AddToCollectionZone from './components/AddToCollectionZone';
import BottomRightContainer from './components/BottomRightContainer';

import bubbleUtils from 'bubble-utils';

import './AlbumCard.scss';

const AlbumCard = (props) => {
  const dispatch = useDispatch();

  const addPrintsToWishlist = useAddPrintsToWishlist();
  const removePrintsFromWishlist = useRemovePrintsFromWishlist();

  const isAlbumInCart = makeIsAlbumInCart();

  const album = useSelector((state) => state.albums.albums[props.albumObjectId]) || props.album;

  const print =
    useSelector(
      (state) => state.prints.prints[props.printObjectId || album?.defaultSellingPrintObjectId],
    ) || props.print;
  const serie =
    useSelector((state) => state.series.series[album?.serie?.objectId]) ||
    props.serie ||
    album?.serie;

  // routes such as agenda do not store series
  const user = useSelector((state) => state.user.user);
  const isOwned = useSelector((state) => isPrintOwned(state, print?.objectId));
  const isInCart = useSelector((state) => isAlbumInCart(state, album?.defaultSellingPrintObjectId));
  const isWishlist = useSelector((state) => isPrintInWishlist(state, print?.objectId));

  const price = print?.sellingInfo?.price || album?.price;
  const images = print?.images;
  const serieTitle = serie?.title || '';

  const pubDate = new Date(print?.publicationDate);
  const hasBeenReleased =
    pubDate >= bubbleUtils.date.getDateModuloXMonths(-1) &&
    pubDate <= bubbleUtils.date.getDateModuloXMonths(6);

  useEffect(() => {
    if (props.printObjectId && !print?.objectId) {
      dispatch(loadPrint({ objectId: props.printObjectId }));
    }
  }, [dispatch, print?.objectId, props.printObjectId]);

  const publicationDate = new Date(print?.publicationDate);

  const albumLink = `/${album?.permalink}/album/${album?.objectId}${
    print?.objectId ? `/${print?.objectId}` : ''
  }`;

  const authors = []
    .concat(Array.isArray(album?.authors) ? album?.authors : [])
    .map((author) => ({
      name: compressAuthorName(author),
      permalink: author.permalink,
      objectId: author.objectId,
    }))
    .filter(Boolean)
    .slice(0, 3)
    .map((author) => (
      <Link
        key={`${album?.objectId}${author.objectId}`}
        className="no-decoration"
        to={`/${author.permalink}/author/${author.objectId}`}
      >
        {author.name}
      </Link>
    ));

  const handleWishlistClick = () => {
    if (isWishlist) {
      removePrintsFromWishlist(user, [print]);
    } else {
      addPrintsToWishlist(user, [print]);
    }
  };

  const containerClassName = [props.className, 'album-card'].filter(Boolean).join(' ');

  return (
    <div className={containerClassName}>
      <div className="position-relative add-to-collection-container">
        <AddToCollectionZone user={user} album={album} serie={serie} print={print} />
      </div>
      <div className="rounded bg-white p-3 nart-shadow">
        <Link className="mb-3 no-decoration" to={albumLink}>
          <div className="position-relative">
            <Cover
              className="album-card-image"
              alt={`Couverture de l'album ${album?.title || serieTitle}`}
              imageUrl={images?.front?.medium}
            />

            {(hasBeenReleased || props.forcePublicationDate) && (
              <div className="bg-white px-2 bb-s-text-size release-container">
                Sortie le{' '}
                {bubbleUtils.date.formatDateWithFormat(publicationDate, {
                  year: null,
                  month: '2-digit',
                })}
              </div>
            )}
            <BottomRightContainer
              albumObjectId={props.albumObjectId}
              editorChoice={album?.editorChoice}
            />
          </div>
        </Link>
        <div className="d-grid pt-2">
          <Link to={albumLink} className="text-black no-decoration link-to-primary-color">
            <div className="fw-bold overflow-hidden" style={{ height: 43 }}>
              <div className="album-card-title">
                {serieTitle}
                {album?.tome && <span className="text-secondary"> - Tome {album?.tome}</span>}
              </div>
            </div>
          </Link>
          <div className="text-truncate text-secondary" style={{ height: 20 }}>
            {authors?.reduce((prev, cur, index) => [prev, index !== 0 && ', ', cur], [])}
          </div>
          <div className="d-flex justify-content-between pt-1">
            <StarLine short note={album?.note} />
            <div className="text-end fw-bold d-flex align-items-center">
              <Tooltip
                tip={
                  isWishlist ? 'Retirer de la liste de souhaits' : 'Ajouter à la liste de souhaits'
                }
              >
                <WithClickHandler onClick={handleWishlistClick}>
                  <img
                    className="album-card-wishlist-icon"
                    style={{ width: 15, height: 15 }}
                    alt="wishlist"
                    src={
                      isWishlist
                        ? getAssetImage(
                            `icon_wishlist_on${isWishlist.isGifted ? '_ticked' : ''}.svg`,
                          )
                        : getAssetImage('icon_wishlist_off.svg')
                    }
                  />
                </WithClickHandler>
              </Tooltip>
              {price ? (
                <div className="ps-2">{bubbleUtils.currency.formatCurrency(price)}</div>
              ) : (
                ''
              )}
            </div>
          </div>

          <AddToCartZone
            availabilities={props.availabilities}
            print={print}
            album={album}
            isOwned={isOwned}
            isInCart={isInCart}
          />
        </div>

        {'bottomAddon' in props && props.bottomAddon}
      </div>

      {'coverAddon' in props && props.coverAddon}
    </div>
  );
};

AlbumCard.propTypes = {
  albumObjectId: PropTypes.string,
};

export default AlbumCard;
