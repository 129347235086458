import React from 'react';

import { Link } from 'react-router-dom';

import WebpWrapper from '@/components/ImageWrapper/WebpWrapper';

import imgBd from '@/assets/home/bd_group.jpg';
import imgBdWebp from '@/assets/home/bd_group.webp';
import imgComics from '@/assets/home/comics_cover.jpg';
import imgComicsWebp from '@/assets/home/comics_cover.webp';
import imgGiftCard from '@/assets/home/gift_card_home.jpg';
import imgGiftCardWebp from '@/assets/home/gift_card_home.webp';
import imgMangas from '@/assets/home/mangas_group.jpg';
import imgMangasWebp from '@/assets/home/mangas_group.webp';
import imgVisualNovel from '@/assets/home/romansgraphiques_cover.png';
import imgVisualNovelWebp from '@/assets/home/romansgraphiques_cover.webp';

import './MainCategories.scss';

const MainCategories = () => {
  return (
    <div>
      <div className="row mobile-horizontal-scroll">
        <BookCategory
          className="col-md-6 col pb-3"
          category="Bande dessinée"
          text="Héros incontournables ou nouveautés tendance, retrouvez le meilleur de la Bande dessinée."
          jpg={imgBd}
          webp={imgBdWebp}
          to="/list?category=bd"
        />
        <BookCategory
          className="col-md-6 col pb-3"
          category="Mangas"
          text="La BD asiatique n'a rien à envier aux catalogues européens et américains par sa richesse infinie."
          jpg={imgMangas}
          webp={imgMangasWebp}
          to="/list?category=mangas"
        />

        <BookCategory
          className="col-md-4 col pb-3"
          small
          category="Comics"
          text="Un univers bien plus vaste qui ne s'arrête pas qu'aux super héros."
          jpg={imgComics}
          webp={imgComicsWebp}
          to="/list?category=comics"
        />
        <BookCategory
          className="col-md-4 col pb-3"
          small
          category="Romans graphiques"
          text="Docus, bio ou fiction, le genre s'impose comme la star des librairies."
          jpg={imgVisualNovel}
          webp={imgVisualNovelWebp}
          to="/list?category=BD&genre=Roman%20graphique"
        />

        <Link
          className="col-md-4 col pb-3 d-none d-md-flex flex-fill"
          to="/bb-gift-card-80/product/gift-card/w9pdlcpOnl"
        >
          <WebpWrapper
            alt="cheques kdo"
            style={{ width: '100%', objectFit: 'contain' }}
            default={imgGiftCard}
            webp={imgGiftCardWebp}
          />
        </Link>
      </div>
    </div>
  );
};

const BookCategory = ({ className, to, jpg, webp, category, small, text }) => {
  return (
    <Link className={`${className || ''} d-flex flex-fill no-decoration text-black`} to={to}>
      <div className="main-category-container bg-white d-flex h-100">
        <div className="d-flex flex-column h-100 justify-content-center">
          <WebpWrapper
            default={jpg}
            webp={webp}
            alt={category}
            className={`main-category-image${small ? '-small' : ''} me-md-3`}
          />

          <div className="d-md-none fw-bold d-flex h-100 justify-content-center align-items-end">
            <div>{category}</div>
          </div>
        </div>
        <div className="d-none d-md-block">
          <div className="fw-bold pb-2 link-to-primary-color">{category}</div>
          <p>{text}</p>
        </div>
      </div>
    </Link>
  );
};

const areEqual = (prevProps, nextProps) => true;

export default React.memo(MainCategories, areEqual);
