import React, { useEffect } from 'react';

import { PROJECT } from '@/project-constants';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { loadAlbums } from 'bubble-reducers/src/reducers/albums';
import { loadArticles } from 'bubble-reducers/src/reducers/articles';
import { loadBanners } from 'bubble-reducers/src/reducers/banners';
import { loadCategories } from 'bubble-reducers/src/reducers/categories';
import { loadGenres } from 'bubble-reducers/src/reducers/genres';
import { loadSeries } from 'bubble-reducers/src/reducers/series';

import { getSeoForListing } from '@/services/seo-utils';

import AlbumCard from '@/components/AlbumCard/AlbumCard';
import ArticleCard from '@/components/ArticleCard/ArticleCard';
import BreadCrumbs from '@/components/Breadcrumbs/BreadCrumbs';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import InfinityCard from '@/components/InfinityCard/InfinityCard';
import SerieCard from '@/components/SerieCard/SerieCard';
import ShareZone from '@/components/ShareZone/ShareZone';
import TitledListContainer from '@/components/TitledListContainer/TitledListContainer';

import ListHeader from './components/ListHeader';
import ListSelection from './components/ListSelections';

import './Listing.css';

const Listing = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);

  const categoryParam = (urlParams.get('category') || '').toLowerCase() || null;
  const genreParam = (urlParams.get('genre') || '').toLowerCase() || null;
  const tagsParam = (urlParams.get('tags') || '').toLowerCase() || null;

  const series = useSelector((state) => state.series.series);
  const mapOfSerieIdsClassic = useSelector((state) => state.series.mapOfSerieIdsClassic);
  const mapOfSerieIdsTopRated = useSelector((state) => state.series.mapOfSerieIdsTopRated);
  const mapOfSeriesIdsTeamFavorite = useSelector(
    (state) => state.series.mapOfSeriesIdsTeamFavorite,
  );
  const mapOfAlbumIdsLastPublished = useSelector(
    (state) => state.albums.mapOfAlbumIdsLastPublished,
  );
  const mapOfAlbumIdsBestSellers = useSelector((state) => state.albums.mapOfAlbumIdsBestSellers);
  const articlesMap = useSelector((state) => state.articles.articlesMapByObjectId);
  const authors = useSelector((state) => state.authors.authors);
  const menuAuthorsObjectIds = useSelector((state) => state.authors.menuAuthorsObjectIds);
  const categories = useSelector((state) => state.categories.categories);
  const genres = useSelector((state) => state.genres.genres);
  const banners = useSelector((state) => state.banners.banners);
  const selectedListingBannerId = useSelector(
    (state) =>
      state.banners.selectedListingBannerIdsMap[categoryParam] ||
      state.banners.selectedListingBannerIdsMap['promo'] ||
      state.banners.selectedListingBannerIdsMap['bd'],
  );

  useEffect(() => {
    dispatch(loadArticles());
    dispatch(loadBanners());
    dispatch(
      loadSeries({
        options: {
          is_classic: 1,
          category: categoryParam || null,
          genre: genreParam ? decodeURIComponent(genreParam) : null,
          tags: tagsParam ? decodeURIComponent(tagsParam) : null,
        },
      }),
    );
    dispatch(
      loadSeries({
        options: {
          is_top_rated: 1,
          category: categoryParam || null,
          genre: genreParam ? decodeURIComponent(genreParam) : null,
          tags: tagsParam ? decodeURIComponent(tagsParam) : null,
        },
      }),
    );
    dispatch(
      loadSeries({
        options: {
          is_team_favorite: 1,
          category: categoryParam || null,
          genre: genreParam ? decodeURIComponent(genreParam) : null,
          tags: tagsParam ? decodeURIComponent(tagsParam) : null,
        },
      }),
    );
    dispatch(
      loadAlbums({
        options: {
          last_published: 1,
          category: categoryParam || null,
          genre: genreParam ? decodeURIComponent(genreParam) : null,
          tags: tagsParam ? decodeURIComponent(tagsParam) : null,
        },
      }),
    );
    dispatch(
      loadAlbums({
        options: {
          best_sellers: 1,
          category: categoryParam || null,
          genre: genreParam ? decodeURIComponent(genreParam) : null,
          tags: tagsParam ? decodeURIComponent(tagsParam) : null,
        },
      }),
    );

    if (categoryParam && !genreParam) {
      dispatch(loadCategories());
    }
    if (genreParam) {
      dispatch(loadGenres());
    }
  }, [location.search]);

  const getKeyForList = (categoryString, genreString, tagsString) => {
    return (categoryString || '') + (genreString || '') + (tagsString || '') || 'all';
  };

  //TODO: category as id, genre as id ? (/listing?cat=12&genre=4564)
  const categoryName = (categoryParam || '').toLowerCase();
  const genreName = genreParam || null;
  let tags = (tagsParam || '').split(',').filter((row) => row) || null;
  if (!!tags && tags.length === 0) {
    // prevents tags being []
    tags = null;
  }

  const category = {
    name: categoryName,
    text: (categories.find((category) => category.nameFrench?.toLowerCase() === categoryName) || {})
      .descriptionShort,
  };

  const genre = {
    name: genreName,
    text: (
      (genres.find((genre) => genre.nameFrench?.toLowerCase() === genreName) || {})[categoryName] ||
      {}
    ).descriptionShort,
  };
  const authorsMenu = (menuAuthorsObjectIds[categoryName] || []).map(
    (objectId) => authors[objectId],
  );
  const articles = Object.values(articlesMap).filter((article) =>
    article?.tags?.some(
      (tag) => tag?.toLowerCase() === categoryName || tag?.toLowerCase() === genreName,
    ),
  );

  const listKey = [
    PROJECT.CATEGORY_NAMES_SHORT[category.name],
    genre.name ? `"${genre.name?.trim()}"` : null,
    tags ? tags.map((tag) => `"${tag}"`).join(', ') : null,
  ]
    .filter(Boolean)
    .join(' ');

  const renderAgenda = () => {
    const lastPublishedAlbums = (
      mapOfAlbumIdsLastPublished[
        getKeyForList(category.name, genre.name, (tags || []).join(','))
      ] || []
    ).slice(0, 4);

    return !!lastPublishedAlbums.length ? (
      <TitledListContainer
        noWrap
        title={
          <>
            <div>Nouveautés et prochaines sorties {listKey}</div>
            <Link className="d-md-none fw-normal bb-regular-text-size pt-2" to="/agenda">
              Voir tout
            </Link>
          </>
        }
        topRightLink={
          <Link className="d-none d-md-block" to="/agenda">
            Voir tout
          </Link>
        }
        list={lastPublishedAlbums.map((albumObjectId) => (
          <AlbumCard
            className="col-7 col-xl-3 col-lg-3 col-md-4 pb-4"
            key={`albumcard-${albumObjectId}`}
            forcePublicationDate={true}
            albumObjectId={albumObjectId}
          />
        ))}
      />
    ) : null;
  };

  const renderBestSellingAlbums = () => {
    const bestSellersAlbums = (
      mapOfAlbumIdsBestSellers[getKeyForList(category.name, genre.name, (tags || []).join(','))] ||
      []
    ).slice(0, 6);

    return !!bestSellersAlbums.length ? (
      <TitledListContainer
        noWrap
        title={<h2 className="h5 m-0 p-0">Meilleures ventes {listKey}</h2>}
        list={bestSellersAlbums.slice(0, 4).map((albumObjectId) => (
          <AlbumCard
            className="col-7 col-xl-3 col-lg-3 col-md-4"
            key={`albumcard-${albumObjectId}`}
            albumObjectId={albumObjectId}
          />
        ))}
      />
    ) : null;
  };

  const renderTeamFavoriteSeries = () => {
    const teamFavoriteSeries = (
      mapOfSeriesIdsTeamFavorite[
        getKeyForList(category.name, genre.name, (tags || []).join(','))
      ] || []
    )
      .map((serieObjectId) => series[serieObjectId])
      .filter((row) => row)
      .slice(0, 6);

    return !!teamFavoriteSeries.length ? (
      <TitledListContainer
        title={<h2 className="h5 m-0 p-0">Coups de coeur {listKey} de la rédaction Bubble</h2>}
        list={teamFavoriteSeries.map((serie) => (
          <div key={`team-favorite-${serie.objectId}`} className="col-md-6 col-lg-4 mt-3">
            <SerieCard white serieObjectId={serie.objectId} />
          </div>
        ))}
      />
    ) : null;
  };

  const renderClassicSeries = () => {
    const classicSeries = (
      mapOfSerieIdsClassic[getKeyForList(category.name, genre.name, (tags || []).join(','))] || []
    )
      .map((serieObjectId) => series[serieObjectId])
      .filter((row) => row)
      .slice(0, 6);

    return !!classicSeries.length ? (
      <TitledListContainer
        title={<h2 className="h5 m-0 p-0">Les grands classiques {listKey}</h2>}
        list={classicSeries.map((serie) => (
          <div key={`classic-${serie.objectId}`} className="col-md-6 col-lg-4 mt-3">
            <SerieCard serieObjectId={serie.objectId} />
          </div>
        ))}
      />
    ) : null;
  };

  const renderTopRatedSeries = () => {
    const topRatedSeries = (
      mapOfSerieIdsTopRated[getKeyForList(category.name, genre.name, (tags || []).join(','))] || []
    )
      .map((serieObjectId) => series[serieObjectId])
      .filter((row) => row)
      .slice(0, 6);

    return !!topRatedSeries.length ? (
      <TitledListContainer
        title={
          <>
            <h2 className="h5 m-0 p-0">
              Séries {listKey} les mieux notées par les membres de Bubble
            </h2>
            <Link
              className="d-md-none fw-normal bb-regular-text-size pt-2"
              to="/tops/topBestNotedSeriesAllTime"
            >
              Voir le top
            </Link>
          </>
        }
        topRightLink={
          <Link className="d-none d-md-block" to="/tops/topBestNotedSeriesAllTime">
            Voir le top
          </Link>
        }
        list={topRatedSeries.map((serie) => (
          <div key={`top_rated_${serie.objectId}`} className="col-md-6 col-lg-4 mt-3">
            <SerieCard serieObjectId={serie.objectId} />
          </div>
        ))}
      />
    ) : null;
  };

  const getLinkForGenre = (genre) => {
    return encodeURI(`/list?category=${categoryParam}&genre=${genre.nameFrench}`);
  };

  const banner =
    selectedListingBannerId && banners[selectedListingBannerId]
      ? banners[selectedListingBannerId]
      : {};
  const seo = getSeoForListing(category, genre);
  return (
    <div className="bb-background-light-grey">
      <BubbleHelmet seo={seo} />
      <div className="container">
        <div className="d-flex align-items-center">
          <BreadCrumbs
            currentObject={{ listing: true, category: category.name, genre: genre.name }}
          />
          <ShareZone title="Partager cette sélection avec des amis" />
        </div>
        <div className="row px-sm-3">
          <div className="d-none d-lg-block col-md-2">
            <ListSelection category={category} genre={genre} tags={tags} authors={authorsMenu} />
            <div className="d-none d-lg-block">
              <InfinityCard />
            </div>
          </div>
          <div className="col-12 col-lg-10">
            <ListHeader genre={genre} tags={tags} category={category} banner={banner} />
            {genres
              .filter((g) => g[category.name?.toLowerCase()])
              .map((genreFromProps) => (
                <Link
                  key={`all_genres_${genreFromProps.objectId}`}
                  to={getLinkForGenre(genreFromProps)}
                  className="me-2 link-to-primary-color"
                >
                  {genreFromProps.nameFrench}
                </Link>
              ))}

            {renderClassicSeries()}
            <div className="h-separator" />
            {renderAgenda()}
            <div className="h-separator" />
            {renderTopRatedSeries()}
            <div className="h-separator" />
            {renderTeamFavoriteSeries()}
            <div className="h-separator" />
            {renderBestSellingAlbums()}
          </div>
        </div>
      </div>
      <div className="nart-background-grey">
        <div className="container">
          <div className="px-sm-3">
            <TitledListContainer
              noWrap
              title={
                <>
                  <div>
                    Suivez toute l'actualité {PROJECT.CATEGORY_NAMES_SHORT[category.name]}
                    {genre.name ? ` "${genre.name}" ` : ' '}sur notre blog
                  </div>
                  <Link className="d-md-none fw-normal bb-regular-text-size pt-2" to="/9emeart">
                    Accéder au blog Bubble
                  </Link>
                </>
              }
              topRightLink={
                <Link className="d-none d-md-block" to="/9emeart">
                  Accéder au blog Bubble
                </Link>
              }
              list={(articles.length ? articles : Object.values(articlesMap))
                .slice(0, 4)
                .map((article) => (
                  <ArticleCard
                    className="col-10 col-md-6 col-lg-3 pt-3"
                    key={article.objectId}
                    article={article}
                  />
                ))}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listing;
