import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { loadSeriesAtAlphanumericIndex } from 'bubble-reducers/src/reducers/series';

import { getSeoForIndexedSeries } from '../../services/seo-utils';

import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';

import AlphanumericBar from './components/AlphanumericBar';
import Header from './components/Header';

const IndexSeries = () => {
  const dispatch = useDispatch();
  const pageIndex = useParams().pageIndex || '';
  const index = pageIndex === 'nouveautes' ? 'new' : pageIndex;
  const series = useSelector((state) => state.series.seriesAlphanumericIndexMap[index]) || [];

  useEffect(() => {
    dispatch(loadSeriesAtAlphanumericIndex({ index }));
  }, [index, dispatch]);

  return (
    <>
      <BubbleHelmet seo={getSeoForIndexedSeries(index)} />
      <Header selected="series" />
      <div className="container">
        <div className="py-4">
          <AlphanumericBar selected={index} type="series" />
        </div>
        {series.map((serie) => {
          const titleAddons = [
            serie.collection ? serie.collection : null,
            serie.type ? serie.type : null,
          ].filter((row) => row);
          return (
            <div key={serie.objectId}>
              <Link
                className="link-to-primary-color no-decoration"
                to={`/${serie.permalink}/serie/${serie.objectId}`}
              >
                {serie.title}
                <span className="font-italic">
                  {!!titleAddons.length && ' - '}
                  {[serie.collection ? serie.collection : null, serie.type ? serie.type : null]
                    .filter((row) => row)
                    .join(' - ')}
                </span>
              </Link>
            </div>
          );
        })}
        <div className="py-4" />
      </div>
    </>
  );
};

export default IndexSeries;
