import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  createAvailabilityAlert,
  deleteAvailabilityAlert,
} from 'bubble-reducers/src/reducers/availability-alerts';
import { addToCart } from 'bubble-reducers/src/reducers/cart';
import { getGuestId, getUserId } from 'bubble-reducers/src/selectors';

import { getItemFullAvailability, getItemOnlineAvailability } from 'bubble-utils/src/album-utils';

import { useIsUserlogged } from '@/services/hooks/useUserUtils';

import Toaster from '@/components/DUPLICATE/Toaster/Toaster';
import AddedToCartModal from '@/components/Modals/AddedToCartModal';
import GuestAvailabilityAlertModal from '@/components/Modals/GuestAvailabilityAlertModal';

import './AddToCartZone.scss';

const AddToCartZone = (props) => {
  const dispatch = useDispatch();
  const isUserlogged = useIsUserlogged();

  const userObjectId = useSelector(getUserId);
  const guestUserId = useSelector(getGuestId);
  const print = props.print;
  const availabilityAlert = useSelector(
    (state) => state.availabilityAlerts.availabilityAlertsMapByPrintObjectId[print?.objectId],
  );

  const [hovered, _setHovered] = useState(false);
  const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);
  const [showGuestAvailabilityAlertModal, setShowGuestAvailabilityAlertModal] = useState(false);

  const fullAvailability = props.availabilities || getItemFullAvailability(print);
  const onlineAvailability = getItemOnlineAvailability(print);
  const price = print?.sellingInfo?.price;

  fullAvailability.message =
    price !== null
      ? fullAvailability.message === 'Non disponible (via Bubble)'
        ? 'Non disponible'
        : fullAvailability.message
      : 'Non disponible';

  //   console.log('fullAvailability.message', fullAvailability.message);

  const handleAvailabilityAlert = async () => {
    if (!!availabilityAlert && !userObjectId && !!guestUserId) {
      return dispatch(
        deleteAvailabilityAlert({
          userObjectId: guestUserId,
          availabilityAlertObjectId: availabilityAlert.objectId,
        }),
      );
    }
    if (isUserlogged(false, null, true)) {
      if (!!availabilityAlert) {
        dispatch(
          deleteAvailabilityAlert({
            userObjectId,
            availabilityAlertObjectId: availabilityAlert.objectId,
          }),
        );
      } else {
        await dispatch(
          createAvailabilityAlert({ userObjectId, printObjectId: print?.objectId }),
        ).unwrap();

        // Toaster.addToast(
        //   'Bravo ! Alerte activée 🔔',
        //   'On vous préviendra par email quand cet article sera commandable.\nAssurez vous que votre email soit bien renseigné dans mon profil>mes informations',
        // );
      }
    } else {
      handleShowGuestAvailabilityAlertModal();
    }
  };

  const addAlbumToCart = (e) => {
    e.stopPropagation();
    if (props.isOwned) {
      if (
        window.confirm(
          "Cet album fait déjà parti de votre collection, Êtes vous sûr de vouloir l'ajouter à votre panier ?",
        )
      ) {
        dispatch(addToCart({ prints: print }));
        setShowAddedToCartModal(true);
      }
    } else {
      dispatch(addToCart({ prints: print }));
      setShowAddedToCartModal(true);
    }
  };

  const handleShowGuestAvailabilityAlertModal = () => {
    setShowGuestAvailabilityAlertModal(true);
  };

  const handleMouseEnter = useCallback(() => _setHovered(true), [_setHovered]);
  const handleMouseLeave = useCallback(() => _setHovered(false), [_setHovered]);

  return (
    <>
      {showAddedToCartModal && (
        <AddedToCartModal
          show={showAddedToCartModal}
          itemIds={[print].map((print) => print.objectId)}
          callback={() => setShowAddedToCartModal(false)}
        />
      )}

      {(onlineAvailability.isOrderable || props.isInCart) && (
        <button
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={addAlbumToCart}
          disabled={!fullAvailability.atLeastOneChannel || price === null}
          className={`btn btn-add-to-cart mt-2 ${props.className || ''}`}
        >
          <span className="d-none d-md-block">
            {props.isInCart
              ? 'Déjà au panier'
              : !(!fullAvailability.atLeastOneChannel || props.isInCart) && hovered
                ? 'Ajouter au panier'
                : fullAvailability.message}
          </span>
          <span className="d-block d-md-none">
            {props.isInCart ? 'Déjà au panier' : 'Ajouter au panier'}
          </span>
        </button>
      )}
      {!onlineAvailability.isOrderable && !props.isInCart && (
        <>
          {showGuestAvailabilityAlertModal && (
            <GuestAvailabilityAlertModal
              show={showGuestAvailabilityAlertModal}
              printObjectId={print?.objectId}
              callback={() => setShowGuestAvailabilityAlertModal(false)}
            />
          )}
          <button
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleAvailabilityAlert}
            className={`btn btn-add-to-cart mt-2 ${props.className || ''}`}
          >
            <span className="d-none d-md-block">
              {!!availabilityAlert ? 'Alerte activée' : 'Alertez-moi par email'}
            </span>
            <span className="d-block d-md-none">
              {props.isInCart ? 'availabilityAlert' : 'Alerte email'}
            </span>
          </button>
        </>
      )}
    </>
  );
};

AddToCartZone.propTypes = {
  print: PropTypes.object,
  className: PropTypes.string,
  isInCart: PropTypes.bool,
  isOwned: PropTypes.bool,
};

export default AddToCartZone;
