import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { editUser } from 'bubble-reducers/src/reducers/user';
import { getUserOrGuest } from 'bubble-reducers/src/selectors';

import { setNewsletterOptIn } from '@/engine/reducers/website-interface';

import { isValidEmail } from 'bubble-utils/src/validity-utils';

import { Icon } from '@/components/Icon/Icon';
import LabeledTextInput from '@/components/LabeledTextInput/LabeledTextInput';

import pinkLight from '@/assets/icon_alert.svg';

import './NewsletterOptIn.scss';

const NewsletterOptIn = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserOrGuest);
  const newsletterOptIn = useSelector((state) => state.websiteInterface.newsletterOptIn);

  const [email, setEmail] = useState(user?.email || '');

  useEffect(() => {
    dispatch(setNewsletterOptIn({ newsletterOptIn: false }));
  }, []);

  const handleButton = useCallback(
    (value) => {
      if (!isValidEmail(value)) {
        alert('Votre email semble incorrect');
      } else {
        dispatch(
          editUser({
            userObjectId: user.objectId,
            user: {
              email: value,
              hasAcceptedNewsletter: true,
              hasAcceptedNewsletterCommercial: true,
            },
          }),
        );
        dispatch(setNewsletterOptIn({ newsletterOptIn: true }));
      }
    },
    [dispatch, user?.objectId],
  );

  return (
    <div className={`sidebar-optin-background rounded`}>
      <div className="d-flex flex-fill h-100 align-items-center p-4">
        {!newsletterOptIn && (
          <>
            <img className="light-icon" alt="light" src={pinkLight} />
            <div className="d-flex flex-column flex-fill ps-3">
              <p className={`fw-bold my-0${'big' in props ? ' big-text' : ''}`}>
                Chaque semaine, toute l'effervescence de la bande dessinée dans votre boîte mail
              </p>
              <div className="mb-n2 pt-2">
                {!!user?.objectId ? (
                  <LabeledTextInput
                    value={email}
                    noRightLabel
                    type="email"
                    white
                    buttonLabel={props.buttonText || `Go\u00A0!`}
                    onChange={(e) => setEmail(e.target.value)}
                    buttonCallback={handleButton}
                    buttonStyle={`w-25 btn btn-bubble-color no-start-border`}
                  />
                ) : (
                  <Link to="/login?nl=t" className="btn btn-bubble-color">
                    Je m'inscris !
                  </Link>
                )}
              </div>
            </div>
          </>
        )}
        {newsletterOptIn && (
          <>
            <div className="bg-success rounded-circle d-flex align-items-center justify-content-center valid-circle">
              <Icon className="text-white bb-l-text-size" name="check" />
            </div>
            <p className={`ps-3 fw-bold my-0${'big' in props ? ' big-text' : ''}`}>
              Votre inscription à la newsletter a été prise en compte.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default NewsletterOptIn;
